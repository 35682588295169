<template>
  <div ref="myElement" class="ourwork_page">
    <div v-if="tab_class">
      <el-image
        class="logoimg"
        v-if="config"
        :src="setHttps(config.logo)"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="menu"
        :src="require('@/assets/img/menu1.png')"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="arrows"
        :src="require('@/assets/img/menu2.png')"
      ></el-image>
      <div class="menu_box_bg"></div>
      <div v-if="config" class="menu_box" :class="{ menuShow: menuState }">
        <i class="menu_close el-icon-close" @click.stop="menuState = false"></i>
        <div class="menu_logo_box">
          <el-image class="menu_logo" :src="setHttps(config.logo)"></el-image>
        </div>
        <div
          v-for="(item, index) in tag_list"
          :key="index"
          @click.stop="scrollTo(index)"
          class="menu_tab_item sethand"
        >
          <div
            class="menu_tab_item_text"
            :class="{ active: activeSection === index }"
          >
            {{ tag_tit_list[index] }}
          </div>
          <div class="menu_tab_item_cover"></div>
        </div>
      </div>

      <div class="card cont3">
        <div
          class="cont3_rom1_box"
          :style="{ 'background-image': `url(${setHttps(page_config.image)})` }"
        >
          <div class="cont3_rom1 nndfade_in4">
            <el-row type="flex" justify="center">
              <el-col :span="22">
                <el-row>
                  <el-col :span="24" class="r1">{{ page_config.title }}</el-col>
                  <el-col :span="24" class="r2">
                    <div
                      v-if="page_config"
                      class="r2_text"
                      v-html="page_config.description.replace(/\n/g, '<br>')"
                    ></div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>

        <!-- 内容展示区 -->
        <div class="cont3_rom">
          <div class="cont3_rom_title">
            <div
              v-for="(item, index) in tab_class"
              :key="index"
              class="cont3_rom_title_item sethand"
            >
              <div
                class="cont3_rom_title_item_room"
                @click.stop="tabClick(index)"
              >
                <div
                  class="cont3_rom_title_item_room_text"
                  :class="{ active: tab_num === index }"
                >
                  {{ item.name }}
                </div>
                <el-image
                  v-if="!(index === tab_class.length - 1)"
                  class="cont3_rom_title_item_room_img"
                  :src="require('@/assets/img/gaplink.png')"
                ></el-image>
              </div>
            </div>
          </div>

          <div v-if="list_arr" class="cont3_rom_list">
            <div
              v-for="(item, index) in list_arr"
              :key="index"
              class="cont3_rom_list_item animate__animated"
              :class="{
                animate__zoomIn:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
                animate__flipInX:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
                animate__flipInY:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
                animate__fadeInDownBig:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
                animate__fadeInLeftBig:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
                animate__fadeInRightBig:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
                animate__fadeInUpBig:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
                animate__rubberBand:
                  (index + (Math.floor(Math.random() * 10) + 10)) %
                  (Math.floor(Math.random() * 3) + 1),
              }"
              :style="{ 'background-image': `url(${setHttps(item.image)})` }"
            >
              <!-- hover时淡入的元素 -->
              <div class="cont3_rom_list_item_product" @click="jumpDetail(item.id)">
                <div class="cont3_rom_list_item_product_shade">
                  <div class="cont3_rom_list_item_shade_box">
                    <div class="cont3_rom_list_item_shade_box_top">
                      {{ item.title }}
                    </div>
                    <div class="cont3_rom_list_item_shade_box_bot">
                      <el-image
                        class="bot_icon sethand move_l"
                        @click="jumpDetail(item.id)"
                        :src="require('@/assets/img/ic1.png')"
                      ></el-image>
                      <el-image
                        class="bot_icon sethand move_r"
                        @click.stop="previewClick(list_arr)"
                        :src="require('@/assets/img/ic2.png')"
                      ></el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="width: 100%; min-height: 100vh"></div>
        </div>

        <div
          v-if="config"
          class="cont3_rom2_box"
          :style="{ 'background-image': `url(${setHttps(config.image)})` }"
        >
          <div
            class="cont3_rom2 animate__animated"
            :class="{ animate__fadeInUp: fadein }"
          >
            <el-row type="flex" justify="center">
              <el-col :span="22">
                <el-row>
                  <el-col :span="24" style="margin-bottom: 3.6458vw">
                    <div class="cont7_img_box">
                      <el-image
                        class="cont7_item_img bounce"
                        :src="setHttps(config.logo)"
                      ></el-image>
                    </div>
                  </el-col>
                  <el-col
                    class="text2"
                    :span="24"
                    style="margin-bottom: 1.8229vw"
                    >{{ config.title }}</el-col
                  >
                  <el-col
                    class="text3"
                    :span="24"
                    style="margin-bottom: 5.7291vw"
                    >{{ config.description }}</el-col
                  >
                  <el-col
                    :lg="24"
                    :md="24"
                    :sm="24"
                    :xs="24"
                    class="text4"
                    style="margin-bottom: 1.8229vw"
                  >
                    <el-row class="text4_cont" type="flex" justify="center">
                      <el-col :lg="12" :md="14" :sm="16" :xs="18"
                        >Copyright ©
                        {{
                          config.url_year === my_time('y')
                            ? my_time('y')
                            : `${config.url_year}-${my_time('y')}`
                        }}
                        {{ config.company }}. 版权所有
                        <span
                          class="sethand"
                          @click.stop="jumpLink(config.icp_url)"
                          >{{ config.icp }}</span
                        ></el-col
                      >
                    </el-row>
                  </el-col>
                  <el-col v-if="config.security" class="text4" :span="24">
                    <div
                      class="gicon sethand"
                      @click.stop="jumpLink(config.security_url)"
                    >
                      <el-image
                        class="gicon_img"
                        :src="require('@/assets/img/g1.png')"
                      ></el-image>
                      <div class="gicon_text">{{ config.security }}</div>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      // loading: false,
      config: '',
      page_config: '',

      menuState: false, //抽屉状态

      activeSection: '', // 关于我们详情不在菜单中
      tag_list: ['#tag1', '#tag2', '#tag3', '#tag4', '#tag5', '#tag6', '#tag7'],
      // tag_tit_list: ['首页', '关于我们', '服务介绍', '产品介绍', '合作客户', '联系我们', '宏驰'],
      // tag_tit_list: ['HOME', 'OUR FEATURE', 'OUR PRODUCT', 'OUR SOLUTIONS', 'WHERE TO BUY', 'CONTACT US', 'HONCHITEC'],
      tag_tit_list: [
        'HOME',
        'OUR FEATURE',
        'OUR PRODUCT',
        'PRODUCT VIDEO',
        'CONTACT US',
        'NEWS',
        'HONCHITEC',
      ],

      param: {
        classify_id: '',
      },

      tab_class: [],
      tab_num: 0, //默认选中第一个分类
      list_arr: '',

      fadein: false,
      scrollState: false,
      resize_state1: false,
      resize_state2: false,
      resize_state3: false,
      resize_state4: false,
      resize_state5: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.getConfig()
    this.getPageConfig()
    this.getClass()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    // 重绘
    resizeHandler() {
      let viewportWidth = window.innerWidth
      console.log('===视口===', viewportWidth)
      // 当视口宽度大于等于 1921 时执行的操作
      if (viewportWidth >= 1921) {
        if (!this.resize_state1) {
          this.resize_state1 = true
          setTimeout(() => {
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state1 = false
      }
      // 当视口宽度大于等于 1200 且小于 1921 时执行的操作
      if (viewportWidth >= 1200 && viewportWidth < 1921) {
        if (!this.resize_state2) {
          this.resize_state2 = true
          setTimeout(() => {
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state2 = false
      }
      // 当视口宽度大于等于 960 且小于 1199 时执行的操作
      if (viewportWidth >= 960 && viewportWidth < 1199) {
        if (!this.resize_state3) {
          this.resize_state3 = true
          setTimeout(() => {
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state3 = false
      }
      // 当视口宽度大于等于 768 且小于 959 时执行的操作
      if (viewportWidth >= 768 && viewportWidth < 959) {
        if (!this.resize_state4) {
          this.resize_state4 = true
          setTimeout(() => {
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state4 = false
      }
      // 当视口宽度大于等于 768 且小于 959 时执行的操作
      if (viewportWidth < 768) {
        if (!this.resize_state5) {
          this.resize_state5 = true
          setTimeout(() => {
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state5 = false
      }
    },
    // 预览图片
    previewClick(pathArr) {
      let imgArr = pathArr.map((i) => this.setHttps(i.image))
      this.$viewerApi({ images: imgArr })
    },
    // 跳转详情
    jumpDetail(id) {
      this.$router.push({ name: 'detail', query: { id: id } })
    },
    // 跳转首页某个锚点
    scrollTo(anchor) {
      this.$router.push({ name: 'panelblock', query: { anchor: anchor } })
    },
    // 门把手
    doorknob() {
      this.menuState = !this.menuState
    },
    // 获取配置信息
    getConfig() {
      this.$('/api/index/getConfig').then((res) => {
        console.log('配置信息1===', res.data)
        this.config = res.data
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll('link').forEach((link) => {
          if (link.rel === 'icon') {
            link.parentNode?.removeChild(link)
          }
        })
        const link = document.createElement('link')
        link.rel = 'icon'
        link.href = this.setHttps(res.data.icon) // 将网络图片的URL赋给href属性
        document.head.appendChild(link)

        // 设置页面的元信息
        document.title = this.config.seo_title
        let metaDescription = document.querySelector('meta[name="description"]')
        metaDescription.setAttribute('content', this.config.seo_description)
        let metaKeywords = document.querySelector('meta[name="keywords"]')
        metaKeywords.setAttribute('content', this.config.seo_keywords)
      })
    },
    // 获取页面配置
    getPageConfig() {
      this.$('/api/Product/getProductConfig').then((res) => {
        console.log('页面配置信息===', res.data)
        this.page_config = res.data
      })
    },
    // 获取分类
    getClass() {
      this.$('/api/Product/getProductClassify').then((res) => {
        console.log('分类数据', res.data)
        this.tab_class = res.data
        this.getInfo() // 产品列表数据
      })
    },
    // 切换tab
    tabClick(num) {
      this.list_arr = ''
      this.tab_num = num
      this.getInfo() // 产品列表数据
    },
    // 获取数据
    getInfo() {
      this.param.classify_id = this.tab_class[this.tab_num].id
      this.$('/api/Product/getProduct', this.param).then((res) => {
        console.log('产品列表数据===', res.data)
        this.list_arr = res.data
        window.addEventListener('resize', this.resizeHandler)
      })
    },

    // 是否到footer
    handleScroll() {
      if (!this.scrollState) {
        this.scrollState = true
        setTimeout(() => {
          let element = this.$el.querySelector('.cont3_rom2_box')
          if (this.isElementInViewport(element)) {
            this.fadein = true
          } else {
            this.fadein = false
          }

          this.scrollState = false
        }, 100)
      }
    },
    isElementInViewport(element) {
      let rect = element.getBoundingClientRect()
      return (
        rect.top <=
        (window.innerHeight || document.documentElement.clientHeight)
      )
    },
  },
}
</script>
<style lang="scss" scoped>
body {
  min-height: 148px;
}

.ourwork_page {
  overflow-x: hidden;
  .logoimg {
    width: 200px;
    height: 66.5px;
    position: fixed;
    top: 5px;
    left: 90px;
    z-index: 99000;
    // opacity: 1;
    // transform: rotate(0deg);
    // transition: transform 0.3s;
    ::v-deep {
      .el-image__inner {
        object-fit: contain !important;
      }
    }
  }
  // 菜单
  .menu {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 99000;
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  .menu:hover {
    opacity: 0;
    transform: rotate(180deg);
    transition: transform 0.2s;

    & + .arrows {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  .arrows {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 90000;
    opacity: 0;
    transform: rotate(-180deg);
    transition: transform 0.2s;
  }

  .menu_box {
    width: 320px;
    height: 100vh;
    background-color: #212121;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.64);
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 99900;
    transition: left 0.3s ease-in-out;
    overflow: auto;

    /* 或者使用 overflow: scroll; */
    /* 添加过渡效果 */
    &.menuShow {
      left: 0;
    }

    .menu_close {
      margin-left: 30px;
      color: #878787;
      font-size: 48px;

      &:hover {
        color: #fff;
      }
    }

    .menu_logo_box {
      width: 100%;
      padding: 15px 30px 80px;

      .menu_logo {
        width: 260px;
        height: 66px;
      }
    }

    .menu_tab_item {
      width: 100%;
      height: 46px;
      position: relative;

      .menu_tab_item_text {
        width: 100%;
        height: 46px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        padding: 0 30px;
        line-height: 46px;
        border-bottom: 1px solid #303030;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #878787;

        &.active {
          background-color: #404040;
          color: #ffffff;
        }

        &:hover {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .menu_tab_item_cover {
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background-color: #404040;
      }

      .menu_tab_item_text:hover + .menu_tab_item_cover {
        width: 100%;
        height: 100%;
        transition: width 0.25s;
      }

      .menu_tab_item_text:not(:hover) + .menu_tab_item_cover {
        width: 0;
        transition: width 1s;
      }
    }
  }

  .card {
    height: 100vh;

    &.cont3 {
      width: 100%;

      // 内容撑开
      .cont3_rom1_box {
        height: auto;
        min-height: 100vh;
        background-size: cover !important;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .cont3_rom1 {
          width: 100%;
          max-width: 1200px;
          height: auto;
          min-height: 100vh;
          margin: 0 auto;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          row-gap: 50px;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #fff;
          padding: 50px 20px;

          .r1 {
            padding-bottom: 30px;
            font-size: 48px;
            font-family: Raleway-Regular, Raleway;
            font-weight: 400;
            color: #ffffff;

            .red {
              color: #e5002f;
            }
          }

          .r2 {
            .r2_text {
              line-height: 2;
              font-size: 24px;
              font-family: Raleway-Regular, Raleway;
              font-weight: 400;
              color: #ffffff;
            }
          }

          @media (max-width: 767px) {
            .r1 {
              font-size: 32px;
            }

            .r2 {
              font-size: 14px;
            }
          }
        }
      }

      .cont3_rom {
        width: 100%;
        overflow: hidden;
        // max-width: 1200px;
        margin: 0 auto;
        padding: 50px 0;

        // background-color: #0ff;
        .cont3_rom_title {
          padding: 0 30px 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          .cont3_rom_title_item {
            display: inline-block;

            .cont3_rom_title_item_room {
              display: grid;
              grid-template-columns: auto auto;
              column-gap: 18px;
              justify-content: center;
              align-items: center;

              .cont3_rom_title_item_room_text {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #474747;
                line-height: 30px;

                &.active {
                  color: #e5002f;
                }
              }

              .cont3_rom_title_item_room_img {
                width: 11px;
                height: 17px;
                margin-right: 18px;
              }
            }
          }
        }

        .cont3_rom_list {
          padding: 0 30px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 30px;
          column-gap: 30px;
          height: 100%;

          .cont3_rom_list_item {
            width: 100%;
            height: 100%;
            background-size: cover !important;
            background-position: center;
            background-repeat: no-repeat;

            .cont3_rom_list_item_product {
              width: 100%;
              padding-top: 73%;
              position: relative;

              .cont3_rom_list_item_product_shade {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 100;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 6, 6, 0.8);
                opacity: 0;
                transition: all 0.5s ease;
                .cont3_rom_list_item_shade_box {
                  width: 100%;
                  height: 100%;
                  display: grid;
                  grid-template-columns: repeat(1, 1fr);
                  justify-content: center;
                  align-content: center;
                  row-gap: 30px;

                  .cont3_rom_list_item_shade_box_top {
                    text-align: center;
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                  }

                  .cont3_rom_list_item_shade_box_bot {
                    display: grid;
                    grid-template-columns: 33px 33px;
                    column-gap: 66px;
                    justify-content: center;
                    align-items: center;

                    .bot_icon {
                      width: 33px;
                      height: 33px;
                      border-radius: 50%;
                    }

                    .bot_icon:hover {
                      background-color: #212121;
                      transition: background-color 1s ease;
                    }
                  }
                }
              }

              .cont3_rom_list_item_product_shade:hover {
                opacity: 0.8;

                .cont3_rom_list_item_shade_box {
                  .cont3_rom_list_item_shade_box_top {
                    animation: movey_t 0.5s ease-in-out forwards;
                  }

                  .cont3_rom_list_item_shade_box_bot {
                    .move_l {
                      animation: movey_l 0.3s ease-in-out forwards;
                    }

                    .move_r {
                      animation: movey_r 0.3s ease-in-out forwards;
                    }
                  }
                }

                /* 添加过渡效果 */
              }
            }
          }
        }

        @media (min-width: 1921px) {
          .cont3_rom_list {
            grid-template-columns: repeat(6, 1fr);
          }
        }

        @media (min-width: 1200px) and (max-width: 1920px) {
          .cont3_rom_list {
            grid-template-columns: repeat(4, 1fr);
          }
        }

        @media (min-width: 960px) and (max-width: 1199px) {
          .cont3_rom_list {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        @media (min-width: 768px) and (max-width: 959px) {
          .cont3_rom_list {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        @media (max-width: 767px) {
          .cont3_rom_list {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }

      .cont3_rom2_box {
        height: auto;
        min-height: 100vh;
        overflow: hidden;
        background-size: cover !important;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .cont3_rom2 {
          width: 100%;
          max-width: 1200px;
          height: auto;
          min-height: 100vh;
          margin: 0 auto;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          justify-content: center;
          align-items: center;
          text-align: center;

          .text2 {
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }

          .text3 {
            font-size: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #878787;
          }

          .text4 {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #878787;
            text-align: center;

            // .text4_cont {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;

            // }

            .gicon {
              display: flex;
              justify-content: center;
              align-items: center;

              .gicon_img {
                width: 16px;
                height: 16px;
              }

              .gicon_text {
                padding-left: 5px;
              }
            }
          }

          .cont7_img_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .cont7_item_img {
              width: 520px;
              height: 95px;
            }
          }
        }
      }

      .cont3_box {
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        /* 隐藏IE和Edge浏览器的滚动条 */
        scrollbar-width: none;

        /* 隐藏Firefox浏览器的滚动条 */
        .cont3_row_col {
          text-align: center;
          display: inline-block;
          width: 25vw;
          height: 100%;
          color: #fff;
          font-size: 48px;

          .cont3_row_col_box {
            width: 100%;
            height: 100%;
            position: relative;
            background-size: cover !important;
            background-position: center;
            background-repeat: no-repeat;

            .col_box1 {
              font-size: 50px;
              font-family: Raleway-Bold, Raleway;
              font-weight: bold;
              color: #ffffff;
              line-height: 60px;
            }

            .col_box2 {
              width: 100%;
              height: 100%;
              overflow: hidden;

              .col_box2_top {
                width: 100%;
                height: 50vh;
                background-size: cover !important;
                background-position: center;
                background-repeat: no-repeat;
              }

              .col_box2_bot {
                width: 100%;
                height: 50vh;
                padding: 45px 30px;
                position: relative;

                &.bgc_49 {
                  background-color: rgba(49, 49, 49, 1);

                  .col_title {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                    font-size: 18px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: #ffffff;
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    justify-items: flex-start;
                    align-content: flex-end;

                    .col_title_text {
                      padding-bottom: 19px;
                    }

                    .col_title_link {
                      width: 40px;
                      height: 1px;
                      background-color: #fff;
                    }
                  }

                  .col_cont {
                    padding: 30px 0 56px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    word-wrap: break-word;
                    white-space: normal;
                    text-align: left;
                  }

                  .col_btn_box {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                  }
                }

                &.bgc_255 {
                  background-color: #fff;

                  .col_title {
                    border-bottom: 1px solid #e0e0e0;
                    font-size: 18px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: #474747;
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    justify-items: flex-start;
                    align-content: flex-end;

                    .col_title_text {
                      padding-bottom: 19px;
                    }

                    .col_title_link {
                      width: 40px;
                      height: 1px;
                      background-color: #474747;
                    }
                  }

                  .col_cont {
                    padding: 30px 0 56px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #474747;
                    word-wrap: break-word;
                    white-space: normal;
                    text-align: left;
                  }

                  .col_btn_box {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #474747;
                  }
                }

                .top_triangle {
                  position: absolute;
                  top: 0;
                  margin-top: -39px;
                  left: 0;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-top-width: 0;
                  border-right-width: 0;
                  border-bottom-width: 40px;
                  border-left-width: 100vw;
                  border-top-color: transparent;
                  border-right-color: transparent;
                  border-left-color: transparent;

                  &.borderc_49 {
                    border-bottom-color: rgba(49, 49, 49, 1);
                  }

                  &.borderc_255 {
                    border-bottom-color: #fff;
                  }
                }

                @media (min-width: 1200px) {
                  .top_triangle {
                    border-left-width: 25.5vw;
                  }
                }

                @media (min-width: 960px) {
                  .top_triangle {
                    border-left-width: 34vw;
                  }
                }

                @media (min-width: 720px) {
                  .top_triangle {
                    border-left-width: 50vw;
                  }
                }
              }
            }
          }
        }
      }

      .cont3_box::-webkit-scrollbar {
        display: none;
        /* 隐藏Chrome和Safari浏览器的滚动条 */
      }
    }
  }

  // 公共title
  .base_cont_title_box {
    background-color: #ccc;
    padding: 48px 0;
  }

  .base_cont_title_room {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
  }

  .base_cont_title {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;

    .base_cont_title_l {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .base_cont_title_c {
      display: inline-block;
      vertical-align: bottom;
      font-size: 48px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }

    .base_cont_title_r {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .title_link {
      width: 40px;
      height: 1px;
      background-color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1180px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 42px;
      }
    }

    .cont7_item_img {
      width: 420px !important;
      height: 76.73px !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 36px;
      }

      .title_link {
        width: 30px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 33.333vw !important;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 28px;
      }

      .title_link {
        width: 30px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 50vw !important;
    }
  }

  @media (max-width: 767px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 24px;
      }

      .title_link {
        width: 20px;
      }
    }

    .cont7_item_img {
      width: 220px !important;
      height: 40.19px !important;
    }

    &::v-deep .el-carousel__arrow--left {
      display: block !important;
    }

    &::v-deep .el-carousel__arrow--right {
      display: block !important;
    }

    .cont3_row_col {
      width: 100vw !important;
    }

    .cont3_rom {
      padding: 50px 0 0 !important;
    }

    .cont3_rom_list {
      padding: 0 !important;
      display: grid;
      grid-template-columns: repeat(1, 1fr) !important;
      row-gap: 0 !important;
      // column-gap: 0;
      height: 100%;
    }
  }

  .showbarhei {
    &::v-deep .el-icon-close:before {
      color: #fff;
    }

    //
    &::v-deep .el-dialog {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
    }

    &::v-deep .el-dialog__header {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
    }
  }
}
</style>
