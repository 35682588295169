<template>
  <div ref="myElement" class="detail_page">
    <div v-if="page_info">
      <el-image
        class="logoimg"
        v-if="config"
        :src="setHttps(config.logo)"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="menu"
        :src="require('@/assets/img/menu1.png')"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="arrows"
        :src="require('@/assets/img/menu2.png')"
      ></el-image>
      <div class="menu_box_bg"></div>
      <div v-if="config" class="menu_box" :class="{ menuShow: menuState }">
        <i class="menu_close el-icon-close" @click.stop="menuState = false"></i>
        <div class="menu_logo_box">
          <el-image class="menu_logo" :src="setHttps(config.logo)"></el-image>
        </div>
        <div
          v-for="(item, index) in tag_list"
          :key="index"
          @click.stop="scrollTo(index)"
          class="menu_tab_item"
        >
          <div
            class="menu_tab_item_text"
            :class="{ active: activeSection === index }"
          >
            {{ tag_tit_list[index] }}
          </div>
          <div class="menu_tab_item_cover"></div>
        </div>
      </div>

      <div class="card cont3">
        <div class="cont3_rom1_box">
          <el-carousel
            :style="{ width: '100%', height: '100%' }"
            type="fade"
            :interval="2000"
            :loop="true"
          >
            <el-carousel-item
              v-for="(item, index) in page_info.images.split(',')"
              :key="index"
            >
              <el-image
                class="banner_item"
                fit="cover"
                :src="setHttps(item)"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
          <div class="info_bar">
            <div class="info_bar_box">
              <el-row type="flex" justify="center">
                <el-col
                  :xl="23"
                  :lg="23"
                  :md="23"
                  :sm="23"
                  :xs="23"
                  class="info_bar_box_top"
                  >{{ page_info.title }}</el-col
                >
              </el-row>
              <el-row type="flex" justify="center" class="hidden-xs-only">
                <el-col :xl="23" :lg="23" :md="23" :sm="23" :xs="23">
                  <div class="info_bar_box_bot">
                    <div class="info_bar_box_bot_item">
                      <el-image
                        class="info_bar_box_bot_item_img"
                        fit="cover"
                        :src="require('@/assets/img/d1.png')"
                      ></el-image>
                      <div class="info_bar_box_bot_item_text">
                        <span class="values tit">作者</span>
                        <span class="values">{{ page_info.author }}</span>
                      </div>
                      <el-image
                        class="info_bar_box_bot_item_link"
                        fit="cover"
                        :src="require('@/assets/img/gaplink.png')"
                      ></el-image>
                    </div>
                    <div class="info_bar_box_bot_item">
                      <el-image
                        class="info_bar_box_bot_item_img"
                        fit="cover"
                        :src="require('@/assets/img/d2.png')"
                      ></el-image>
                      <div class="info_bar_box_bot_item_text">
                        <span class="values tit">类别</span>
                        <span class="values">{{
                          page_info.classify_name
                        }}</span>
                      </div>
                      <el-image
                        class="info_bar_box_bot_item_link"
                        fit="cover"
                        :src="require('@/assets/img/gaplink.png')"
                      ></el-image>
                    </div>
                    <div class="info_bar_box_bot_item">
                      <el-image
                        class="info_bar_box_bot_item_img"
                        fit="cover"
                        :src="require('@/assets/img/d3.png')"
                      ></el-image>
                      <div class="info_bar_box_bot_item_text">
                        <span class="values tit">日期</span>
                        <span class="values">{{ page_info.release_time }}</span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>

        <div class="rich_content" v-html="htmlContent"></div>
        <!-- 内容展示区 -->
        <div class="cont3_rom">
          <el-row type="flex" justify="center" class="cont3_rom_title_room">
            <el-col
              :xl="23"
              :lg="23"
              :md="23"
              :sm="23"
              :xs="23"
              class="cont3_rom_title"
              >MORE PRODUCTS</el-col
            >
          </el-row>

          <swiper class="swiper_box" ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              class="cont3_rom_swiper"
              v-for="(item, index) in page_info.recommendProductList"
              :key="index"
            >
              <div
                class="cont3_rom_swiper_col animate__animated"
                :class="{
                  animate__zoomIn:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                  animate__flipInX:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                  animate__flipInY:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                  animate__fadeInDownBig:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                  animate__fadeInLeftBig:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                  animate__fadeInRightBig:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                  animate__fadeInUpBig:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                  animate__rubberBand:
                    (index + (Math.floor(Math.random() * 10) + 10)) %
                    (Math.floor(Math.random() * 3) + 1),
                }"
                :style="{ 'background-image': `url(${setHttps(item.image)})` }"
              >
                <!-- hover时淡入的元素 -->
                <div class="cont3_rom_swiper_col_product_shade" @click="jumpDetail(item.id)">
                  <div class="cont3_rom_swiper_col_shade_box">
                    <div class="cont3_rom_swiper_col_shade_box_top">
                      {{ item.title }}
                    </div>
                    <div class="cont3_rom_swiper_col_shade_box_bot">
                      <el-image
                        class="bot_icon sethand move_l"
                        @click.stop="jumpDetail(item.id)"
                        :src="require('@/assets/img/ic1.png')"
                      ></el-image>
                      <el-image
                        class="bot_icon sethand move_r"
                        @click.stop="
                          previewClick(page_info.recommendProductList)
                        "
                        :src="require('@/assets/img/ic2.png')"
                      ></el-image>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="turning_btn_box">
            <div class="turning_l sethand" @click.stop="leftClick">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="turning_r sethand" @click.stop="rightClick">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div
          class="cont3_rom2_box"
          :style="{ 'background-image': `url(${setHttps(config.image)})` }"
        >
          <div
            class="cont3_rom2 animate__animated"
            :class="{ animate__fadeInUp: fadein }"
          >
            <el-row type="flex" justify="center">
              <el-col :span="22">
                <el-row>
                  <el-col :span="24" style="margin-bottom: 3.6458vw">
                    <div class="cont7_img_box">
                      <el-image
                        class="cont7_item_img bounce"
                        :src="setHttps(config.logo)"
                      ></el-image>
                    </div>
                  </el-col>
                  <el-col
                    class="text2"
                    :span="24"
                    style="margin-bottom: 1.8229vw"
                    >{{ config.title }}</el-col
                  >
                  <el-col
                    class="text3"
                    :span="24"
                    style="margin-bottom: 5.7291vw"
                    >{{ config.description }}</el-col
                  >
                  <el-col
                    :lg="24"
                    :md="24"
                    :sm="24"
                    :xs="24"
                    class="text4"
                    style="margin-bottom: 1.8229vw"
                  >
                    <el-row class="text4_cont" type="flex" justify="center">
                      <el-col :lg="12" :md="14" :sm="16" :xs="18"
                        >Copyright ©
                        {{
                          config.url_year === my_time('y')
                            ? my_time('y')
                            : `${config.url_year}-${my_time('y')}`
                        }}
                        {{ config.company }}. 版权所有
                        <span
                          class="sethand"
                          @click.stop="jumpLink(config.icp_url)"
                          >{{ config.icp }}</span
                        ></el-col
                      >
                    </el-row>
                  </el-col>
                  <el-col v-if="config.security" class="text4" :span="24">
                    <div
                      class="gicon sethand"
                      @click.stop="jumpLink(config.security_url)"
                    >
                      <el-image
                        class="gicon_img"
                        :src="require('@/assets/img/g1.png')"
                      ></el-image>
                      <div class="gicon_text">{{ config.security }}</div>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
module.exports = {
  directives: {
    'mouse-drag': {
      bind(el) {
        let isMouseDown = false
        let startX
        let scrollLeft

        el.addEventListener('mousedown', (e) => {
          isMouseDown = true
          startX = e.pageX - el.offsetLeft
          scrollLeft = el.scrollLeft
        })

        el.addEventListener('mouseleave', () => {
          isMouseDown = false
        })

        el.addEventListener('mouseup', () => {
          isMouseDown = false
        })

        el.addEventListener('mousemove', (e) => {
          if (!isMouseDown) return
          e.preventDefault()
          const x = e.pageX - el.offsetLeft
          const walk = (x - startX) * 1 // 控制滑动速度
          el.scrollLeft = scrollLeft - walk
        })
      },
    },
  },
  data: function () {
    return {
      // loading: false,
      config: '',

      menuState: false, //抽屉状态

      activeSection: '', // 关于我们详情不在菜单中
      tag_list: ['#tag1', '#tag2', '#tag3', '#tag4', '#tag5', '#tag6', '#tag7'],
      // tag_tit_list: ['首页', '关于我们', '服务介绍', '产品介绍', '合作客户', '联系我们', '宏驰'],
      // tag_tit_list: ['HOME', 'OUR FEATURE', 'OUR PRODUCT', 'OUR SOLUTIONS', 'WHERE TO BUY', 'CONTACT US', 'HONCHITEC'],
      tag_tit_list: [
        'HOME',
        'OUR FEATURE',
        'OUR PRODUCT',
        'PRODUCT VIDEO',
        'CONTACT US',
        'NEWS',
        'HONCHITEC',
      ],

      param: {
        product_id: '',
      },

      page_info: '',

      fadein: false,
      scrollState: false,

      swiperOptions: {
        pagination: '.swiper-pagination',
        // slidesPerView: 'auto',
        slidesPerView: '4',
        paginationClickable: true,
      },
      resize_state1: false,
      resize_state2: false,
      resize_state3: false,
      resize_state4: false,
      resize_state5: false,
      htmlContent: "",
    }
  },
  created() {},

  mounted() {
    this.getConfig()
    this.getInfo()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    htmlParse(html) {
      const maxWidth = 750
      const imageDomain = this.pathurl
      // 把html中的图片地址替换成网络地址 并且替换图片的width属性为maxWidth 最后返回html
      const htmlStr = html.replace(
        /<img(.*?)src=["']([^"']*)["'](.*?)\/>/gi,
        (match, p1, p2, p3) => {
          // 替换图片地址为网络图片地址
          console.log('p2', p2)
          const url = imageDomain + p2.replace('../../', '')
          // 判断图片是否含有width 如果有则 替换图片的width属性为maxWidth
          if (p3.indexOf('width') > -1) {
            return `<img${p1}src="${url}"${p3.replace(
              /width=["']\d+["']/gi,
              ''
            )} width="${maxWidth}" />`
          } else {
            return `<img${p1}src="${url}"${p3} />`
          }
        }
      )
      return htmlStr
    },
    swiper() {
      let viewportWidth = window.innerWidth
      if (viewportWidth < 768) {
        this.swiperOptions.slidesPerView = '1' // 在视口宽度小于768px时，将slidesPerView修改为2
      }
      setTimeout(() => {
        console.log('===init-swiper', this.$refs.mySwiper)
        return this.$refs.mySwiper.$swiper
      }, 100)
    },
    leftClick() {
      this.$refs.mySwiper.$swiper.slidePrev()
    },
    rightClick() {
      this.$refs.mySwiper.$swiper.slideNext()
    },
    // 重绘
    resizeHandler() {
      let viewportWidth = window.innerWidth
      console.log('===视口===', viewportWidth)
      // 当视口宽度大于等于 1921 时执行的操作
      if (viewportWidth >= 1921) {
        if (!this.resize_state1) {
          this.resize_state1 = true
          setTimeout(() => {
            this.$refs.mySwiper.$swiper.params.slidesPerView = '6' // 在视口宽度小于768px时，将slidesPerView修改为2
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state1 = false
      }
      // 当视口宽度大于等于 1200 且小于 1921 时执行的操作
      if (viewportWidth >= 1200 && viewportWidth < 1921) {
        if (!this.resize_state2) {
          this.resize_state2 = true
          setTimeout(() => {
            this.$refs.mySwiper.$swiper.params.slidesPerView = '5' // 在视口宽度小于768px时，将slidesPerView修改为2
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state2 = false
      }
      // 当视口宽度大于等于 960 且小于 1199 时执行的操作
      if (viewportWidth >= 960 && viewportWidth < 1199) {
        if (!this.resize_state3) {
          this.resize_state3 = true
          setTimeout(() => {
            this.$refs.mySwiper.$swiper.params.slidesPerView = '4' // 在视口宽度小于768px时，将slidesPerView修改为2
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state3 = false
      }
      // 当视口宽度大于等于 768 且小于 959 时执行的操作
      if (viewportWidth >= 768 && viewportWidth < 959) {
        if (!this.resize_state4) {
          this.resize_state4 = true
          setTimeout(() => {
            this.$refs.mySwiper.$swiper.params.slidesPerView = '3' // 在视口宽度小于768px时，将slidesPerView修改为2
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state4 = false
      }
      // 当视口宽度大于等于 768 且小于 959 时执行的操作
      if (viewportWidth < 768) {
        if (!this.resize_state5) {
          this.resize_state5 = true
          setTimeout(() => {
            this.$refs.mySwiper.$swiper.params.slidesPerView = '1' // 在视口宽度小于768px时，将slidesPerView修改为2
            this.$forceUpdate()
          }, 100)
        }
      } else {
        this.resize_state5 = false
      }
    },
    // 预览图片
    previewClick(pathArr) {
      let imgArr = pathArr.map((i) => this.setHttps(i.image))
      this.$viewerApi({ images: imgArr })
    },
    // 跳转详情页新页面打开携带id
    jumpDetail(id) {
      this.page_info = ''
      this.$router.replace({ query: { id: id } })
      this.getInfo()
    },
    // 跳转首页某个锚点
    scrollTo(anchor) {
      this.$router.push({ name: 'panelblock', query: { anchor: anchor } })
    },
    // 门把手
    doorknob() {
      this.menuState = !this.menuState
    },
    // 获取配置信息
    getConfig() {
      this.$('/api/index/getConfig').then((res) => {
        console.log('配置信息1===', res.data)
        this.config = res.data
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll('link').forEach((link) => {
          if (link.rel === 'icon') {
            link.parentNode?.removeChild(link)
          }
        })
        const link = document.createElement('link')
        link.rel = 'icon'
        link.href = this.setHttps(res.data.icon) // 将网络图片的URL赋给href属性
        document.head.appendChild(link)

        // 设置页面的元信息
        document.title = this.config.seo_title
        let metaDescription = document.querySelector('meta[name="description"]')
        metaDescription.setAttribute('content', this.config.seo_description)
        let metaKeywords = document.querySelector('meta[name="keywords"]')
        metaKeywords.setAttribute('content', this.config.seo_keywords)
      })
    },
    // 获取数据
    getInfo() {
      this.$loading()
      this.param.product_id = this.$route.query.id
      console.log('请求参数===', this.param)
      this.$('/api/Product/getProductDetail', this.param)
        .then((res) => {
          console.log('详情数据===', res.data)
          this.page_info = res.data
          this.htmlContent = this.htmlParse(res.data.content)
          window.addEventListener('resize', this.resizeHandler)
          window.addEventListener('scroll', this.handleScroll)
          this.swiper()
        })
        .finally(() => {
          this.$loading().close()
        })
    },
    // 是否到footer
    handleScroll() {
      if (!this.scrollState) {
        this.scrollState = true
        setTimeout(() => {
          let element = this.$el.querySelector('.cont3_rom2_box')
          if (this.isElementInViewport(element)) {
            this.fadein = true
          } else {
            this.fadein = false
          }

          this.scrollState = false
        }, 100)
      }
    },
    isElementInViewport(element) {
      let rect = element.getBoundingClientRect()
      return (
        rect.top <=
        (window.innerHeight || document.documentElement.clientHeight)
      )
    },
  },
}
</script>
<style lang="scss" scoped>
body {
  min-height: 148px;
}

.detail_page {
  .turning_btn_box {
    width: 100px;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 990000;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    font-size: 24px;

    .turning_l {
      width: 100%;
      height: 100%;
      background-color: #212121;
      color: #a7a7a7;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      align-items: center;

      &:hover {
        background: #474747;
        color: #fff;
      }
    }

    .turning_r {
      width: 100%;
      height: 100%;
      background-color: #212121;
      color: #a7a7a7;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      justify-items: center;

      &:hover {
        background: #474747;
        color: #fff;
      }
    }
  }

  .logoimg {
    width: 200px;
    height: 66.5px;
    position: fixed;
    top: 5px;
    left: 90px;
    z-index: 99000;
    // opacity: 1;
    // transform: rotate(0deg);
    // transition: transform 0.3s;
    ::v-deep {
      .el-image__inner {
        object-fit: contain !important;
      }
    }
  }

  // 菜单
  .menu {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 99000;
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  .menu:hover {
    opacity: 0;
    transform: rotate(180deg);
    transition: transform 0.2s;

    & + .arrows {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  .arrows {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 90000;
    opacity: 0;
    transform: rotate(-180deg);
    transition: transform 0.2s;
  }

  .menu_box {
    width: 320px;
    height: 100vh;
    background-color: #212121;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.64);
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 99900;
    transition: left 0.3s ease-in-out;
    overflow: auto;

    /* 或者使用 overflow: scroll; */
    /* 添加过渡效果 */
    &.menuShow {
      left: 0;
    }

    .menu_close {
      margin-left: 30px;
      color: #878787;
      font-size: 48px;

      &:hover {
        color: #fff;
      }
    }

    .menu_logo_box {
      width: 100%;
      padding: 15px 30px 80px;

      .menu_logo {
        width: 260px;
        height: 66px;
      }
    }

    .menu_tab_item {
      width: 100%;
      height: 46px;
      position: relative;

      .menu_tab_item_text {
        width: 100%;
        height: 46px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        padding: 0 30px;
        line-height: 46px;
        border-bottom: 1px solid #303030;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #878787;

        &.active {
          background-color: #404040;
          color: #ffffff;
        }

        &:hover {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .menu_tab_item_cover {
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background-color: #404040;
      }

      .menu_tab_item_text:hover + .menu_tab_item_cover {
        width: 100%;
        height: 100%;
        transition: width 0.25s;
      }

      .menu_tab_item_text:not(:hover) + .menu_tab_item_cover {
        width: 0;
        transition: width 1s;
      }
    }
  }

  .card {
    height: 100vh;

    &.cont3 {
      width: 100%;

      .cont3_rom1_box {
        height: 100vh;
        position: relative;

        .is-active {
          transform: none !important;
          opacity: 0;
          animation: opacity111 1s ease forwards;
        }

        .el-carousel__item {
          transition: transform 0.01s ease-in-out !important;
        }

        @keyframes opacity111 {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        .info_bar {
          width: 100%;
          padding: 40px 0;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 100;
          background-color: rgba(33, 33, 33, 0.7);

          .info_bar_box {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;

            .info_bar_box_top {
              padding: 0 0 10px;
              border-bottom: 1px solid #797979;
              font-size: 47px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              margin-bottom: 15px;
            }

            .info_bar_box_bot {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;

              .info_bar_box_bot_item {
                margin-right: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .info_bar_box_bot_item_img {
                  width: 13px;
                  height: 13px;
                }

                .info_bar_box_bot_item_text {
                  padding-right: 10px;
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #a7a7a7;

                  .values {
                    padding-left: 10px;
                  }

                  .tit {
                    color: #ffffff;
                  }
                }

                .info_bar_box_bot_item_link {
                  width: 9px;
                  height: 14px;
                }
              }
            }
          }
        }

        &::v-deep .el-carousel__container {
          width: 100%;
          height: 100%;
        }

        &::v-deep .el-carousel__arrow {
          width: 60px;
          height: 60px;
          background-color: rgba(0, 0, 0, 0.7);
          font-size: 30px;
        }

        &::v-deep .el-carousel__arrow--left {
          left: 60px;
        }

        &::v-deep .el-carousel__arrow--right {
          right: 60px;
        }

        &::v-deep .el-carousel__indicators--horizontal {
          display: none;
        }

        @media (max-width: 1200px) {
          &::v-deep .el-carousel__arrow--left {
            left: 30px;
          }

          &::v-deep .el-carousel__arrow--right {
            right: 30px;
          }
        }

        .banner_item {
          width: 100%;
          height: 100%;
        }
      }

      .cont3_rom {
        width: 100%;
        margin: 0 auto;
        padding: 100px 0 0;
        position: relative;

        .turning_btn_box {
          width: 100px;
          height: 50px;
          position: absolute;
          bottom: 0;
          right: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;
          font-size: 24px;

          .turning_l {
            width: 100%;
            height: 100%;
            background-color: #212121;
            color: #a7a7a7;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-items: center;
            align-items: center;

            &:hover {
              background: #474747;
              color: #fff;
            }
          }

          .turning_r {
            width: 100%;
            height: 100%;
            background-color: #212121;
            color: #a7a7a7;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            align-items: center;
            justify-items: center;

            &:hover {
              background: #474747;
              color: #fff;
            }
          }
        }

        .cont3_rom_title_room {
          width: 100%;
          padding-bottom: 40px;
          max-width: 1200px;
          margin: 0 auto;

          .cont3_rom_title {
            padding: 0 0 10px;
            border-bottom: 1px solid #e0e0e0;
            font-size: 30px;
            font-family: Raleway-Regular, Raleway;
            font-weight: 400;
            color: #474747;
          }
        }

        .swiper_box {
          background-color: #006064;

          .swiper-slide {
            display: inline-block;
            vertical-align: middle;

            color: #fff;
            font-size: 48px;
            text-align: center;
            position: relative;
            background-size: cover !important;
            background-position: center;
            background-repeat: no-repeat;
          }

          .cont3_rom_swiper {
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
            width: 100%;

            .cont3_rom_swiper_col {
              text-align: center;

              width: 100%;
              padding-top: 52.083%;

              background-size: cover !important;
              background-position: center;
              background-repeat: no-repeat;
              position: relative;

              .cont3_rom_swiper_col_product_shade {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 100;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 6, 6, 0.8);
                opacity: 0;

                .cont3_rom_swiper_col_shade_box {
                  width: 100%;
                  height: 100%;
                  display: grid;
                  grid-template-columns: repeat(1, 1fr);
                  justify-content: center;
                  align-content: center;
                  row-gap: 30px;

                  .cont3_rom_swiper_col_shade_box_top {
                    text-align: center;
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                  }

                  .cont3_rom_swiper_col_shade_box_bot {
                    display: grid;
                    grid-template-columns: 33px 33px;
                    column-gap: 66px;
                    justify-content: center;
                    align-items: center;

                    .bot_icon {
                      width: 33px;
                      height: 33px;
                      border-radius: 50%;
                    }

                    .bot_icon:hover {
                      background-color: #212121;
                      transition: background-color 1s ease;
                    }
                  }
                }
              }

              .cont3_rom_swiper_col_product_shade:hover {
                opacity: 0.8;
                transition: opacity 0.5s ease;

                .cont3_rom_swiper_col_shade_box {
                  .cont3_rom_swiper_col_shade_box_top {
                    animation: movey_t 0.5s ease-in-out forwards;
                  }

                  .cont3_rom_swiper_col_shade_box_bot {
                    .move_l {
                      animation: movey_l 0.3s ease-in-out forwards;
                    }

                    .move_r {
                      animation: movey_r 0.3s ease-in-out forwards;
                    }
                  }
                }

                /* 添加过渡效果 */
              }
            }
          }
        }
      }
      .rich_content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 80px 0 0;
      }

      @media (max-width: 767px) {
        .cont3_rom {
          padding: 30px 0 0;
        }
        .rich_content {
          padding: 30px 0 0 ;
        }

        .cont3_rom_title {
          font-size: 24px !important;
        }
      }

      .cont3_rom2_box {
        height: auto;
        min-height: 100vh;
        overflow: hidden;
        background-size: cover !important;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .cont3_rom2 {
          width: 100%;
          max-width: 1200px;
          height: auto;
          min-height: 100vh;
          margin: 0 auto;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;

          .text2 {
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }

          .text3 {
            font-size: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #878787;
          }

          .text4 {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #878787;
            text-align: center;

            // .text4_cont {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;

            // }

            .gicon {
              display: flex;
              justify-content: center;
              align-items: center;

              .gicon_img {
                width: 16px;
                height: 16px;
              }

              .gicon_text {
                padding-left: 5px;
              }
            }
          }

          .cont7_img_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .cont7_item_img {
              width: 520px;
              height: 95px;
            }
          }
        }
      }

      .cont3_box {
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        /* 隐藏IE和Edge浏览器的滚动条 */
        scrollbar-width: none;

        /* 隐藏Firefox浏览器的滚动条 */
        .cont3_row_col {
          text-align: center;
          display: inline-block;
          width: 25vw;
          height: 100%;
          color: #fff;
          font-size: 48px;

          .cont3_row_col_box {
            width: 100%;
            height: 100%;
            position: relative;
            background-size: cover !important;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  // 公共title
  .base_cont_title_box {
    background-color: #ccc;
    padding: 48px 0;
  }

  .base_cont_title_room {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
  }

  .base_cont_title {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;

    .base_cont_title_l {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .base_cont_title_c {
      display: inline-block;
      // vertical-align: bottom;
      font-size: 48px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }

    @media (max-width: 767px) {
      .base_cont_title_c {
        font-size: 24px;
      }
    }

    .base_cont_title_r {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .title_link {
      width: 40px;
      height: 1px;
      background-color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1180px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 42px;
      }
    }

    .cont7_item_img {
      width: 420px !important;
      height: 76.73px !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 36px;
      }

      .title_link {
        width: 30px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 33.333vw !important;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 28px;
      }

      .title_link {
        width: 30px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 50vw !important;
    }
  }

  @media (max-width: 767px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 24px;
      }

      .title_link {
        width: 20px;
      }
    }

    .cont7_item_img {
      width: 220px !important;
      height: 40.19px !important;
    }

    &::v-deep .el-carousel__arrow--left {
      display: block !important;
    }

    &::v-deep .el-carousel__arrow--right {
      display: block !important;
    }

    .cont3_row_col {
      width: 100vw !important;
    }

    .cont3_rom_list {
      padding: 0 !important;
      display: grid;
      grid-template-columns: repeat(1, 1fr) !important;
      row-gap: 0 !important;
      // column-gap: 0;
      height: 100%;
    }

    .info_bar_box_top {
      font-size: 24px !important;
    }

    .info_bar_box_bot {
      display: none !important;
    }
  }

  .showbarhei {
    &::v-deep .el-icon-close:before {
      color: #fff;
    }

    //
    &::v-deep .el-dialog {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
    }

    &::v-deep .el-dialog__header {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
    }
  }
}
</style>
