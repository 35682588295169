<template>
  <div ref="myElement" class="ourwork_page">
    <div v-if="page_info">
      <el-image
        class="logoimg"
        v-if="config"
        :src="setHttps(config.logo)"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="menu"
        :src="require('@/assets/img/menu1.png')"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="arrows"
        :src="require('@/assets/img/menu2.png')"
      ></el-image>
      <div class="menu_box_bg"></div>
      <div v-if="config" class="menu_box" :class="{ menuShow: menuState }">
        <i class="menu_close el-icon-close" @click.stop="menuState = false"></i>
        <div class="menu_logo_box">
          <el-image class="menu_logo" :src="setHttps(config.logo)"></el-image>
        </div>
        <div
          v-for="(item, index) in tag_list"
          :key="index"
          @click.stop="scrollTo(index)"
          class="menu_tab_item sethand"
        >
          <div
            class="menu_tab_item_text"
            :class="{ active: activeSection === index }"
          >
            {{ tag_tit_list[index] }}
          </div>
          <div class="menu_tab_item_cover"></div>
        </div>
      </div>

      <div class="card cont3">
        <div
          class="cont3_rom1_box"
          :style="{
            'background-image': `url(${setHttps(
              page_info.serviceDetail.image_detail_bg
            )})`,
          }"
        >
          <div class="cont3_rom1 nndfade_in4">
            <el-row type="flex" justify="center">
              <el-col :span="22">
                <el-row>
                  <el-col :span="24" class="r1">{{
                    page_info.serviceDetail.title_detail
                  }}</el-col>
                  <el-col :span="24" class="r2">
                    <div
                      class="r2_text"
                      v-html="
                        page_info.serviceDetail.description_detail.replace(
                          /\n/g,
                          '<br>'
                        )
                      "
                    ></div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>

        <!-- 内容展示区 -->
        <div class="cont3_rom">
          <!-- <div class="cont3_rom_title">{{ page_info.serviceDetail.title_detail }}</div> -->
          <div class="cont3_rom_cont">
            <div class="cont3_rom_cont_list">
              <div
                v-for="(item, index) in page_info.serviceDetailList"
                :key="index"
                class="list_item"
              >
                <!-- 第一行 -->
                <el-row class="list_item_row">
                  <el-col
                    v-if="item.title_position === 2"
                    :xl="12"
                    :lg="12"
                    :md="12"
                    :sm="12"
                    :xs="24"
                  >
                    <div class="col_img">
                      <el-image
                        class="col_img_bgc"
                        :src="setHttps(item.image)"
                        lazy
                      ></el-image>
                    </div>
                  </el-col>
                  <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="col_cont">
                      <div class="col_cont_title p_t_80">{{ item.title }}</div>
                      <div
                        class="col_cont_text"
                        v-html="item.description.replace(/\n/g, '<br>')"
                      ></div>
                    </div>
                  </el-col>
                  <el-col
                    v-if="item.title_position === 1"
                    :xl="12"
                    :lg="12"
                    :md="12"
                    :sm="12"
                    :xs="24"
                  >
                    <div class="col_img">
                      <el-image
                        class="col_img_bgc"
                        :src="setHttps(item.image)"
                        lazy
                      ></el-image>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>

        <div
          class="cont3_rom2_box"
          :style="{ 'background-image': `url(${setHttps(config.image)})` }"
        >
          <div
            class="cont3_rom2 animate__animated"
            :class="{ animate__fadeInUp: fadein }"
          >
            <el-row type="flex" justify="center">
              <el-col :span="22">
                <el-row>
                  <el-col :span="24" style="margin-bottom: 3.6458vw">
                    <div class="cont7_img_box">
                      <el-image
                        class="cont7_item_img bounce"
                        :src="setHttps(config.logo)"
                      ></el-image>
                    </div>
                  </el-col>
                  <el-col
                    class="text2"
                    :span="24"
                    style="margin-bottom: 1.8229vw"
                    >{{ config.title }}</el-col
                  >
                  <el-col
                    class="text3"
                    :span="24"
                    style="margin-bottom: 5.7291vw"
                    >{{ config.description }}</el-col
                  >
                  <el-col
                    :lg="24"
                    :md="24"
                    :sm="24"
                    :xs="24"
                    class="text4"
                    style="margin-bottom: 1.8229vw"
                  >
                    <el-row class="text4_cont" type="flex" justify="center">
                      <el-col :lg="12" :md="14" :sm="16" :xs="18"
                        >Copyright ©
                        {{
                          config.url_year === my_time('y')
                            ? my_time('y')
                            : `${config.url_year}-${my_time('y')}`
                        }}
                        {{ config.company }}. 版权所有
                        <span
                          class="sethand"
                          @click.stop="jumpLink(config.icp_url)"
                          >{{ config.icp }}</span
                        ></el-col
                      >
                    </el-row>
                  </el-col>
                  <el-col v-if="config.security" class="text4" :span="24">
                    <div
                      class="gicon sethand"
                      @click.stop="jumpLink(config.security_url)"
                    >
                      <el-image
                        class="gicon_img"
                        :src="require('@/assets/img/g1.png')"
                      ></el-image>
                      <div class="gicon_text">{{ config.security }}</div>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      // loading: false,
      config: '',

      menuState: false, //抽屉状态

      page_info: '', //页面数据

      activeSection: '', // 关于我们详情不在菜单中
      tag_list: ['#tag1', '#tag2', '#tag3', '#tag4', '#tag5', '#tag6', '#tag7'],
      // tag_tit_list: ['首页', '关于我们', '服务介绍', '产品介绍', '合作客户', '联系我们', '宏驰'],
      // tag_tit_list: ['HOME', 'OUR FEATURE', 'OUR PRODUCT', 'OUR SOLUTIONS', 'WHERE TO BUY', 'CONTACT US', 'HONCHITEC'],
      tag_tit_list: [
        'HOME',
        'OUR FEATURE',
        'OUR PRODUCT',
        'PRODUCT VIDEO',
        'CONTACT US',
        'NEWS',
        'HONCHITEC',
      ],

      param: {
        service_id: '',
      },
      fadein: false,
      scrollState: false,
    }
  },
  created() {
    this.param.service_id = this.$route.query.id
  },
  mounted() {
    this.getConfig()
    this.getInfo()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    // 跳转首页某个锚点
    scrollTo(anchor) {
      this.$router.push({ name: 'panelblock', query: { anchor: anchor } })
    },
    // 门把手
    doorknob() {
      this.menuState = !this.menuState
    },
    // 跳转外链
    jumpLink(url) {
      window.open(url, '_blank')
    },
    // 获取配置信息
    getConfig() {
      this.$('/api/index/getConfig').then((res) => {
        console.log('配置信息===', res.data)
        this.config = res.data
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll('link').forEach((link) => {
          if (link.rel === 'icon') {
            link.parentNode?.removeChild(link)
          }
        })
        const link = document.createElement('link')
        link.rel = 'icon'
        link.href = this.setHttps(res.data.icon) // 将网络图片的URL赋给href属性
        document.head.appendChild(link)

        // 设置页面的元信息
        document.title = this.config.seo_title
        let metaDescription = document.querySelector('meta[name="description"]')
        metaDescription.setAttribute('content', this.config.seo_description)
        let metaKeywords = document.querySelector('meta[name="keywords"]')
        metaKeywords.setAttribute('content', this.config.seo_keywords)
      })
    },

    // 获取数据
    getInfo() {
      this.$loading()
      this.$('/api/Service/service', this.param)
        .then((res) => {
          console.log('页面数据===', res.data)
          this.page_info = res.data
          window.addEventListener('scroll', this.handleScroll)
        })
        .finally(() => {
          this.$loading().close()
        })
    },
    // 是否到footer
    handleScroll() {
      if (!this.scrollState) {
        this.scrollState = true
        setTimeout(() => {
          let element = this.$el.querySelector('.cont3_rom2_box')
          if (this.isElementInViewport(element)) {
            this.fadein = true
          } else {
            this.fadein = false
          }

          this.scrollState = false
        }, 100)
      }
    },
    isElementInViewport(element) {
      let rect = element.getBoundingClientRect()
      return (
        rect.top <=
        (window.innerHeight || document.documentElement.clientHeight)
      )
    },
  },
}
</script>
<style lang="scss" scoped>
body {
  min-height: 148px;
}

.ourwork_page {
  .logoimg {
    width: 200px;
    height: 66.5px;
    position: fixed;
    top: 5px;
    left: 90px;
    z-index: 99000;
    // opacity: 1;
    // transform: rotate(0deg);
    // transition: transform 0.3s;
    ::v-deep {
      .el-image__inner {
        object-fit: contain !important;
      }
    }
  }
  // 菜单
  .menu {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 99000;
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  .menu:hover {
    opacity: 0;
    transform: rotate(180deg);
    transition: transform 0.2s;

    & + .arrows {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  .arrows {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 90000;
    opacity: 0;
    transform: rotate(-180deg);
    transition: transform 0.2s;
  }

  .menu_box {
    width: 320px;
    height: 100vh;
    background-color: #212121;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.64);
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 99900;
    transition: left 0.3s ease-in-out;
    overflow: auto;

    /* 或者使用 overflow: scroll; */
    /* 添加过渡效果 */
    &.menuShow {
      left: 0;
    }

    .menu_close {
      margin-left: 30px;
      color: #878787;
      font-size: 48px;

      &:hover {
        color: #fff;
      }
    }

    .menu_logo_box {
      width: 100%;
      padding: 15px 30px 80px;

      .menu_logo {
        width: 260px;
        height: 66px;
      }
    }

    .menu_tab_item {
      width: 100%;
      height: 46px;
      position: relative;

      .menu_tab_item_text {
        width: 100%;
        height: 46px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        padding: 0 30px;
        line-height: 46px;
        border-bottom: 1px solid #303030;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #878787;

        &.active {
          background-color: #404040;
          color: #ffffff;
        }

        &:hover {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .menu_tab_item_cover {
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background-color: #404040;
      }

      .menu_tab_item_text:hover + .menu_tab_item_cover {
        width: 100%;
        height: 100%;
        transition: width 0.25s;
      }

      .menu_tab_item_text:not(:hover) + .menu_tab_item_cover {
        width: 0;
        transition: width 1s;
      }
    }
  }

  .card {
    height: 100vh;

    &.cont3 {
      width: 100%;

      // 内容撑开
      .cont3_rom1_box {
        height: auto;
        min-height: 100vh;
        background-size: cover !important;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .cont3_rom1 {
          width: 100%;
          max-width: 1200px;
          height: auto;
          min-height: 100vh;
          margin: 0 auto;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          row-gap: 50px;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #fff;
          padding: 50px 20px;

          .r1 {
            padding-bottom: 30px;
            font-size: 48px;
            font-family: Raleway-Regular, Raleway;
            font-weight: 400;
            color: #ffffff;

            .red {
              color: #e5002f;
            }
          }

          .r2 {
            .r2_text {
              line-height: 2;
              font-size: 24px;
              font-family: Raleway-Regular, Raleway;
              font-weight: 400;
              color: #ffffff;
            }
          }
          @media (max-width: 767px) {
            .r1 {
              font-size: 32px;
            }
            .r2 {
              .r2_text {
                font-size: 14px;
              }
            }
          }
        }
      }

      .cont3_rom {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        // background-color: #0ff;

        .cont3_rom_title {
          padding-bottom: 15px;
          border-bottom: 1px solid #eee;
          position: relative;
          margin-bottom: 30px;
          font-size: 48px;
          font-family: Raleway-Regular, Raleway;
          font-weight: 400;
          color: #474747;

          &:before {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            margin-bottom: -1px;
            width: 80px;
            height: 1px;
            background-color: rgba(71, 71, 71, 1);
          }
        }
        @media (max-width: 767px) {
          .cont3_rom_title {
            font-size: 24px;
          }
        }

        .cont3_rom_cont {
          width: 100%;
          background-color: #fff;

          .cont3_rom_cont_list {
            .list_item {
              background-color: #fff;

              .list_item_row {
                width: 100%;

                .col_cont {
                  padding: 0 30px 30px;

                  .col_cont_title {
                    padding-bottom: 15px;
                    border-bottom: 1px solid #eee;
                    position: relative;
                    margin-bottom: 30px;
                    font-size: 48px;
                    font-family: Raleway-Regular, Raleway;
                    font-weight: 400;
                    color: #474747;

                    &:before {
                      position: absolute;
                      content: '';
                      left: 0;
                      bottom: 0;
                      margin-bottom: -1px;
                      width: 80px;
                      height: 1px;
                      background-color: rgba(71, 71, 71, 1);
                    }
                  }
                  @media (max-width: 767px) {
                    .col_cont_title {
                      font-size: 24px;
                    }
                  }
                  .col_cont_text {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #878787;
                  }
                }

                .col_img {
                  position: relative;
                  padding-bottom: 100%;

                  /* 设置高度为宽度的百分比，实现宽高比为1:1 */
                  .col_img_bgc {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                  }

                  .col_img_bgc img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }

      .cont3_rom2_box {
        height: auto;
        min-height: 100vh;
        overflow: hidden;
        background-size: cover !important;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .cont3_rom2 {
          width: 100%;
          max-width: 1200px;
          height: auto;
          min-height: 100vh;
          margin: 0 auto;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;

          .text2 {
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }

          .text3 {
            font-size: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #878787;
          }

          .text4 {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #878787;
            text-align: center;

            // .text4_cont {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;

            // }

            .gicon {
              display: flex;
              justify-content: center;
              align-items: center;

              .gicon_img {
                width: 16px;
                height: 16px;
              }

              .gicon_text {
                padding-left: 5px;
              }
            }
          }

          .cont7_img_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .cont7_item_img {
              width: 520px;
              height: 95px;
            }
          }
        }
      }

      .cont3_box {
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        /* 隐藏IE和Edge浏览器的滚动条 */
        scrollbar-width: none;

        /* 隐藏Firefox浏览器的滚动条 */
        .cont3_row_col {
          text-align: center;
          display: inline-block;
          width: 25vw;
          height: 100%;
          color: #fff;
          font-size: 48px;

          .cont3_row_col_box {
            width: 100%;
            height: 100%;
            position: relative;
            background-size: cover !important;
            background-position: center;
            background-repeat: no-repeat;

            .col_box1 {
              font-size: 50px;
              font-family: Raleway-Bold, Raleway;
              font-weight: bold;
              color: #ffffff;
              line-height: 60px;
            }

            .col_box2 {
              width: 100%;
              height: 100%;
              overflow: hidden;

              .col_box2_top {
                width: 100%;
                height: 50vh;
                background-size: cover !important;
                background-position: center;
                background-repeat: no-repeat;
              }

              .col_box2_bot {
                width: 100%;
                height: 50vh;
                padding: 45px 30px;
                position: relative;

                &.bgc_49 {
                  background-color: rgba(49, 49, 49, 1);

                  .col_title {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                    font-size: 18px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: #ffffff;
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    justify-items: flex-start;
                    align-content: flex-end;

                    .col_title_text {
                      padding-bottom: 19px;
                    }

                    .col_title_link {
                      width: 40px;
                      height: 1px;
                      background-color: #fff;
                    }
                  }

                  .col_cont {
                    padding: 30px 0 56px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    word-wrap: break-word;
                    white-space: normal;
                    text-align: left;
                  }

                  .col_btn_box {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                  }
                }

                &.bgc_255 {
                  background-color: #fff;

                  .col_title {
                    border-bottom: 1px solid #e0e0e0;
                    font-size: 18px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: #474747;
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    justify-items: flex-start;
                    align-content: flex-end;

                    .col_title_text {
                      padding-bottom: 19px;
                    }

                    .col_title_link {
                      width: 40px;
                      height: 1px;
                      background-color: #474747;
                    }
                  }

                  .col_cont {
                    padding: 30px 0 56px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #474747;
                    word-wrap: break-word;
                    white-space: normal;
                    text-align: left;
                  }

                  .col_btn_box {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #474747;
                  }
                }

                .top_triangle {
                  position: absolute;
                  top: 0;
                  margin-top: -39px;
                  left: 0;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-top-width: 0;
                  border-right-width: 0;
                  border-bottom-width: 40px;
                  border-left-width: 100vw;
                  border-top-color: transparent;
                  border-right-color: transparent;
                  border-left-color: transparent;

                  &.borderc_49 {
                    border-bottom-color: rgba(49, 49, 49, 1);
                  }

                  &.borderc_255 {
                    border-bottom-color: #fff;
                  }
                }

                @media (min-width: 1200px) {
                  .top_triangle {
                    border-left-width: 25.5vw;
                  }
                }

                @media (min-width: 960px) {
                  .top_triangle {
                    border-left-width: 34vw;
                  }
                }

                @media (min-width: 720px) {
                  .top_triangle {
                    border-left-width: 50vw;
                  }
                }
              }
            }
          }
        }
      }

      .cont3_box::-webkit-scrollbar {
        display: none;
        /* 隐藏Chrome和Safari浏览器的滚动条 */
      }
    }
  }

  // 公共title
  .base_cont_title_box {
    background-color: #ccc;
    padding: 48px 0;
  }

  .base_cont_title_room {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
  }

  .base_cont_title {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;

    .base_cont_title_l {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .base_cont_title_c {
      display: inline-block;
      vertical-align: bottom;
      font-size: 48px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }

    .base_cont_title_r {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .title_link {
      width: 40px;
      height: 1px;
      background-color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1180px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 42px;
      }
    }

    .cont7_item_img {
      width: 420px !important;
      height: 76.73px !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 36px;
      }

      .title_link {
        width: 30px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 33.333vw !important;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 28px;
      }

      .title_link {
        width: 30px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 50vw !important;
    }
  }

  @media (max-width: 767px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 24px;
      }

      .title_link {
        width: 20px;
      }
    }

    .cont7_item_img {
      width: 220px !important;
      height: 40.19px !important;
    }

    &::v-deep .el-carousel__arrow--left {
      display: block !important;
    }

    &::v-deep .el-carousel__arrow--right {
      display: block !important;
    }

    .cont3_row_col {
      width: 100vw !important;
    }
  }

  .showbarhei {
    &::v-deep .el-icon-close:before {
      color: #fff;
    }

    //
    &::v-deep .el-dialog {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
    }

    &::v-deep .el-dialog__header {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
    }
  }
}
</style>
