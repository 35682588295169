import Vue from 'vue'

//导入页面
import panelblock from '@/views/panelblock/panelblock'
import weare from '@/views/weare/weare'
import ourwork from '@/views/ourwork/ourwork'
import ourdo from '@/views/ourdo/ourdo'
import detail from '@/views/detail/detail'
import productVideo from "@/views/productVideo/productVideo"
import news from "@/views/news/news"
import newsDetail from "@/views/newsDetail/newsDetail"

import Router from 'vue-router' // 导入vue-router
 
Vue.use(Router)

 
//配置页面路由
export const constantRouterMap = [
  {
    path: '/',
    name: 'panelblock',
    component: panelblock,
    meta: {
      title: '首页'
    },
  },
  {
    path: '/weare',
    name: 'weare',
    component: weare,
    meta: {
      title: '我们是谁'
    },
  },
  {
    path: '/ourdo',
    name: 'ourdo',
    component: ourdo,
    meta: {
      title: '我们所做的'
    },
  },
  {
    path: '/ourwork',
    name: 'ourwork',
    component: ourwork,
    meta: {
      title: '我们的产品'
    },
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail,
    meta: {
      title: '详情'
    },
  },
  {
    path: '/productVideo',
    name: 'productVideo',
    component: productVideo,
    meta: {
      title: '视频列表'
    },
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: {
      title: '咨询列表'
    },
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail,
    meta: {
      title: '咨询详情'
    }
  }
  
  
]

const router = new Router({
    routes: constantRouterMap,
    mode:'hash'//history
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '宏驰'; // 设置页面标题
  next();
});
 
export default router
