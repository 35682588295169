<template>
  <div ref="myElement" class="index_page">
    <div v-if="page_info">
      <el-image
        class="logoimg"
        v-if="config"
        :src="setHttps(config.logo)"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="menu"
        :src="require('@/assets/img/menu1.png')"
        @click.stop="doorknob"
      ></el-image>
      <el-image
        class="arrows"
        :src="require('@/assets/img/menu2.png')"
      ></el-image>
      <div class="menu_box_bg"></div>
      <div v-if="config" class="menu_box" :class="{ menuShow: menuState }">
        <i class="menu_close el-icon-close" @click.stop="menuState = false"></i>
        <div class="menu_logo_box">
          <el-image class="menu_logo" :src="setHttps(config.logo)"></el-image>
        </div>
        <div
          v-for="(item, index) in tag_list"
          :key="index"
          @click.stop="scrollTo(index)"
          class="menu_tab_item sethand"
        >
          <div
            class="menu_tab_item_text"
            :class="{ active: activeSection === index }"
          >
            {{ tag_tit_list[index] }}
          </div>
          <div class="menu_tab_item_cover"></div>
        </div>
      </div>
      <!-- 指示点 -->
      <div class="nav_box">
        <div
          v-for="(item, index) in tag_list"
          :key="index"
          class="flex flex-center sethand"
          @click.stop="scrollTo(index)"
        >
          <a class="dot" :class="{ active: activeSection === index }"></a>
        </div>
      </div>

      <div id="tag1" class="card cont1">
        <el-carousel
          :style="{ width: '100%', height: '100%' }"
          type="fade"
          :interval="2500"
          :loop="true"
        >
          <el-carousel-item
            v-for="(item, index) in page_info.bannerList"
            :key="index"
          >
            <el-image
              class="banner_item"
              fit="cover"
              :src="setHttps(item.image)"
              @click.stop="jumpRich(item, 'banner')"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
        <el-image
          class="bot_btn"
          :src="require('@/assets/img/bot1.png')"
          @click.stop="scrollTo(1)"
        ></el-image>
      </div>

      <div
        id="tag2"
        class="card cont3"
        :style="{
          'background-image': `url(${setHttps(page_info.serviceHome.image)})`,
        }"
      >
        <div class="swiper_box">
          <div class="swiper_">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide class="item-device">
                <div
                  class="device"
                  :style="{
                    'background-image': `url(${setHttps(
                      page_info.serviceHome.image
                    )})`,
                  }"
                >
                  <div class="posi-all col_box1">WE DO</div>
                </div>
              </swiper-slide>
              <swiper-slide
                class="item-device"
                v-for="(item, index) in page_info.serviceList"
                :key="index"
              >
                <div class="device">
                  <div class="posi-all col_box2">
                    <div
                      class="col_box2_top scale sethand"
                      @click.stop="jump('ourdo', item.id)"
                      :style="{
                        'background-image': `url(${setHttps(item.image)})`,
                      }"
                    ></div>
                    <div
                      class="col_box2_bot"
                      :class="`${(index + 3) % 2 ? 'bgc_49' : 'bgc_255'}`"
                    >
                      <div
                        class="top_triangle"
                        :class="`${
                          (index + 3) % 2 ? 'borderc_49' : 'borderc_255'
                        }`"
                      ></div>
                      <div class="col_title">
                        <div class="col_title_text">{{ item.title }}</div>
                        <div class="col_title_link"></div>
                      </div>
                      <div class="col_cont">{{ item.description }}</div>
                      <div
                        class="col_btn_box flex flex-end sethand"
                        @click.stop="jump('ourdo', item.id)"
                      >
                        <div class="">更多细节</div>
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="turning_btn_box">
          <div class="turning_l sethand" @click.stop="leftClick">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="turning_r sethand" @click.stop="rightClick">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div id="tag3" class="card cont4">
        <div class="base_cont_title_box">
          <el-row
            type="flex"
            justify="center"
            class="base_cont_title animate__animated"
            :class="{ animate__fadeInDown: activeSection >= 3 }"
          >
            <el-col :span="24">
              <el-row class="base_cont_title_room">
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_l"
                >
                  <div class="title_link"></div>
                </el-col>
                <el-col
                  :lg="10"
                  :md="8"
                  :sm="8"
                  :xs="14"
                  class="h100 base_cont_title_c"
                  >OUR PRODUCTS</el-col
                >
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_r"
                >
                  <div class="title_link"></div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <div class="cont4_box">
          <div
            v-for="(item, index) in page_info.productList"
            :key="index"
            class="cont4_item animate__animated"
            :class="{
              animate__zoomIn:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
              animate__flipInX:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
              animate__flipInY:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
              animate__fadeInDownBig:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
              animate__fadeInLeftBig:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
              animate__fadeInRightBig:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
              animate__fadeInUpBig:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
              animate__rubberBand:
                (index + (Math.floor(Math.random() * 10) + 10)) %
                (Math.floor(Math.random() * 3) + 1),
            }"
            :style="{ 'background-image': `url(${setHttps(item.image)})` }"
          >
            <!-- hover时淡入的元素 -->
            <div class="cont4_item_product" @click="jumpDetail(item.id)">
              <div class="cont4_item_product_shade">
                <div class="cont4_item_shade_box">
                  <div class="cont4_item_shade_box_top">{{ item.title }}</div>
                  <div class="cont4_item_shade_box_bot">
                    <el-image
                      class="bot_icon sethand move_l"
                      @click.stop="jumpDetail(item.id)"
                      :src="require('@/assets/img/ic1.png')"
                    ></el-image>
                    <el-image
                      class="bot_icon sethand move_r"
                      @click.stop="
                        previewClick(setHttps(page_info.productList))
                      "
                      :src="require('@/assets/img/ic2.png')"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="all_products" @click.stop="jump('ourwork')">
          <span class="sethand span">查看所有产品</span>
        </div>
      </div>
      <div
        id="tag4"
        class="card cont5"
        :style="{
          'background-image': `url(${require('@/assets/img/img3.png')})`,
        }"
      >
        <div class="base_cont_title_box">
          <el-row
            type="flex"
            justify="center"
            class="base_cont_title animate__animated"
            :class="{ animate__fadeInDown: activeSection >= 4 }"
          >
            <el-col :span="24">
              <el-row class="base_cont_title_room">
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_l"
                >
                  <div class="title_link"></div>
                </el-col>
                <el-col
                  :lg="10"
                  :md="8"
                  :sm="8"
                  :xs="14"
                  class="h100 base_cont_title_c"
                  >PRODUCT VIDEO</el-col
                >
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_r"
                >
                  <div class="title_link"></div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <!-- <div class="cont5_box">

                    <el-row class="cont5_box_row">
                        <el-col :lg="4" :md="6" :sm="8" :xs="24" v-for="(item, index) in page_info.cooperateClientList"
                            :key="index" class="cont5_item">
                            <div class="cont5_item_img_box">
                                <el-image class="cont5_item_img" :src="setHttps(item.image)"></el-image>
                            </div>
                        </el-col>
                    </el-row>
                    
                </div> -->
        <div class="swiper-box">
          <swiper ref="myProductSwiper" :options="productSwiperOptions">
            <swiper-slide
              class="item-product"
              v-for="(item, index) in productVideoList"
              :key="index"
            >
              <div class="image-box">
                <img
                  :src="setHttps(item.video_image)"
                  alt=""
                  class="animate__animated"
                  :class="{
                    animate__zoomIn:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                    animate__flipInX:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                    animate__flipInY:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                    animate__fadeInDownBig:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                    animate__fadeInLeftBig:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                    animate__fadeInRightBig:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                    animate__fadeInUpBig:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                    animate__rubberBand:
                      (index + (Math.floor(Math.random() * 10) + 10)) %
                      (Math.floor(Math.random() * 3) + 1),
                  }"
                />
                <div class="play-btn">
                  <img
                    src="@/assets/img/play.png"
                    alt=""
                    @click="playVideo(item)"
                  />
                </div>
              </div>
              <div class="text">{{ item.title }}</div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-button-prev"
            slot="button-prev"
            @click.stop="clickVideoPrev"
          >
            <div class="btn-icon"></div>
          </div>
          <div
            class="swiper-button-next"
            slot="button-next"
            @click.stop="clickVideoNext"
          >
            <div class="btn-icon"></div>
          </div>
        </div>

        <div class="more-btn" @click="jumpProduct">查看更多</div>

        <!-- <div class="qidai-box">
          <div class="img-box">
            <img src="@/assets/img/qidai.png" alt="" />
          </div>
          <div class="base_cont_title_box">
            <el-row
              type="flex"
              justify="center"
              class="base_cont_title animate__animated"
              :class="{ animate__fadeInDown: activeSection >= 4 }"
            >
              <el-col :span="24">
                <el-row class="base_cont_title_room">
                  <el-col
                    :lg="7"
                    :md="8"
                    :sm="8"
                    :xs="5"
                    class="base_cont_title_l"
                  >
                    <div class="title_link"></div>
                  </el-col>
                  <el-col
                    :lg="10"
                    :md="8"
                    :sm="8"
                    :xs="14"
                    class="h100 base_cont_title_c"
                  >
                    <div class="qidai1">官方视频</div>
                  </el-col>
                  <el-col
                    :lg="7"
                    :md="8"
                    :sm="8"
                    :xs="5"
                    class="base_cont_title_r"
                  >
                    <div class="title_link"></div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div class="qidai2">敬请期待</div>
          </div>
        </div> -->

        <div class="video-model" v-if="videoShow" @click.stop="closeVideo">
          <div class="video-box">
            <video class="video" controls :src="setHttps(videoUrl)"></video>
          </div>
        </div>
      </div>
      <div
        id="tag5"
        class="card cont6"
        :style="{
          'background-image': `url(${require('@/assets/img/img4.png')})`,
        }"
      >
        <div class="base_cont_title_box">
          <el-row
            type="flex"
            justify="center"
            class="base_cont_title animate__animated"
            :class="{ animate__fadeInDown: activeSection >= 5 }"
          >
            <el-col :span="24">
              <el-row class="base_cont_title_room">
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_l"
                >
                  <div class="title_link"></div>
                </el-col>
                <el-col
                  :lg="10"
                  :md="8"
                  :sm="8"
                  :xs="14"
                  class="h100 base_cont_title_c"
                  >CONTACT US</el-col
                >
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_r"
                >
                  <div class="title_link"></div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="cont6_box">
          <el-row type="flex" justify="center">
            <el-col :lg="23" :md="23" :sm="23" :xs="23">
              <el-row :gutter="14" class="cont6_box_row">
                <el-col
                  :lg="12"
                  :md="12"
                  :sm="12"
                  :xs="24"
                  class="cont6_box_row_col1"
                >
                  <el-row>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                      <el-row :gutter="30">
                        <el-col :lg="12" :md="12" :sm="24" :xs="24">
                          <div class="base_ipt">
                            <input
                              v-model="param.name"
                              placeholder="你的名字"
                              :maxlength="30"
                              type="text"
                            />
                          </div>
                        </el-col>
                        <el-col :lg="12" :md="12" :sm="24" :xs="24">
                          <div class="base_ipt">
                            <input
                              v-model="param.email"
                              placeholder="你的邮箱"
                              :maxlength="30"
                              type="text"
                            />
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                      <div class="base_ipt">
                        <input
                          v-model="param.title"
                          placeholder="你的主题"
                          :maxlength="30"
                          type="text"
                        />
                      </div>
                    </el-col>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                      <div class="base_textarea">
                        <textarea
                          v-model="param.content"
                          placeholder="您的留言"
                          :maxlength="200"
                          type="text"
                        ></textarea>
                      </div>
                    </el-col>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                      <el-row class="sub_btn_box">
                        <el-col
                          :offset="16"
                          :lg="8"
                          :md="8"
                          :sm="8"
                          :xs="8"
                          class="sub_btn"
                        >
                          <div @click.stop="subFun">发送你的信息</div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col
                  :lg="12"
                  :md="12"
                  :sm="12"
                  :xs="24"
                  class="cont6_box_row_col2"
                >
                  <el-row>
                    <el-col
                      :lg="12"
                      :md="24"
                      :sm="24"
                      :xs="24"
                      class="col_items"
                    >
                      <el-row class="tips_box">
                        <el-col :span="4">
                          <el-image
                            class="tips_box_img"
                            :src="require('@/assets/img/ic3.png')"
                          ></el-image>
                        </el-col>
                        <el-col :span="20">
                          <el-row class="tips_box_cont">
                            <el-col :span="24">电话</el-col>
                            <el-col :span="24">{{ config.tel }}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col
                      :lg="12"
                      :md="24"
                      :sm="24"
                      :xs="24"
                      class="col_items"
                    >
                      <el-row class="tips_box">
                        <el-col :span="4">
                          <el-image
                            class="tips_box_img"
                            :src="require('@/assets/img/ic4.png')"
                          ></el-image>
                        </el-col>
                        <el-col :span="20">
                          <el-row class="tips_box_cont">
                            <el-col :span="24">{{ config.company }}</el-col>
                            <el-col :span="24">{{ config.email }}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col
                      :lg="12"
                      :md="24"
                      :sm="24"
                      :xs="24"
                      class="col_items"
                    >
                      <el-row class="tips_box">
                        <el-col :span="4">
                          <el-image
                            class="tips_box_img"
                            :src="require('@/assets/img/ic5.png')"
                          ></el-image>
                        </el-col>
                        <el-col :span="18">
                          <el-row class="tips_box_cont">
                            <el-col :span="24">{{ config.address }}</el-col>
                            <el-col :span="24">{{
                              config.address_english
                            }}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- <div id="tag6" class="card cont2"
                :style="{ 'background-image': `url(${setHttps(page_info.aboutHome.image)})` }"> -->

      <!-- 中间展示部分 -->
      <!-- <div class="cont2_box animate__animated" :class="{ 'animate__fadeInUp': activeSection >= 1 }">

                    <el-row type="flex" justify="center">
                        <el-col :lg="12" :md="16" :sm="20" :xs="24" class="flex flex-center">
                            <el-image class="cont2_box_r1" :src="require('@/assets/img/dp1.png')"></el-image>
                        </el-col>
                    </el-row>
                    <el-row type="flex" justify="center" class="cont2_box_r2">
                        <el-col :lg="12" :md="16" :sm="20" :xs="24">
                            <div class="cont2_box_r2_r">灵感、创造力和无限的可能性。</div>
                        </el-col>
                    </el-row>
                    <el-row type="flex" justify="center">
                        <el-col :lg="12" :md="16" :sm="20" :xs="24" class="cont2_box_r3">
                            <div class="cont2_box_r3_text" v-html="page_info.aboutHome.description"
                                style="white-space: pre-line;"></div>
                        </el-col>
                    </el-row>
                    <el-row class="cont2_box_r4" type="flex" justify="center">
                        <el-col :lg="12" :md="16" :sm="20" :xs="24" class="flex flex-center">
                            <div class="cont2_box_r4_btn" @click.stop="jump('weare')">更多 关于宏驰</div>
                        </el-col>
                    </el-row>


                </div> -->

      <!-- </div> -->

      <!-- 新闻 -->
      <div
        id="tag6"
        class="card cont5"
        :style="{
          'background-image': `url(${require('@/assets/img/news_bg.png')})`,
        }"
      >
        <div class="base_cont_title_box">
          <el-row
            type="flex"
            justify="center"
            class="base_cont_title animate__animated"
            :class="{ animate__fadeInDown: activeSection >= 4 }"
          >
            <el-col :span="24">
              <el-row class="base_cont_title_room">
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_l"
                >
                  <div class="title_link"></div>
                </el-col>
                <el-col
                  :lg="10"
                  :md="8"
                  :sm="8"
                  :xs="14"
                  class="h100 base_cont_title_c"
                  >NEWS</el-col
                >
                <el-col
                  :lg="7"
                  :md="8"
                  :sm="8"
                  :xs="5"
                  class="base_cont_title_r"
                >
                  <div class="title_link"></div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="news-list">
          <swiper
            ref="myNewsSwiper"
            class="swiper"
            :options="newsSwiperOptions"
          >
            <swiper-slide
              class="item-new"
              v-for="(item, index) in newsList"
              :key="index"
            >
              <div class="img-box" @click="toNewsDetail(item.id)">
                <img class="scale" :src="setHttps(item.image)" alt="" />
              </div>
              <div class="img-title" @click="toNewsDetail(item.id)">
                {{ item.title }}
              </div>
              <div class="news-line"></div>
              <div class="news-content" v-if="item.description">
                {{ item.description }}
              </div>
              <div class="detail-btn" @click="toNewsDetail(item.id)">
                <div>查看详情</div>
                <img src="@/assets/img/arrow_right_white.png" alt="" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="more-btn" @click="jumpNews">查看更多</div>
        <!-- <div class="qidai-box">
          <div class="img-box">
            <img src="@/assets/img/qidai.png" alt="" />
          </div>
          <div class="base_cont_title_box">
            <el-row
              type="flex"
              justify="center"
              class="base_cont_title animate__animated"
              :class="{ animate__fadeInDown: activeSection >= 4 }"
            >
              <el-col :span="24">
                <el-row class="base_cont_title_room">
                  <el-col
                    :lg="7"
                    :md="8"
                    :sm="8"
                    :xs="5"
                    class="base_cont_title_l"
                  >
                    <div class="title_link"></div>
                  </el-col>
                  <el-col
                    :lg="10"
                    :md="8"
                    :sm="8"
                    :xs="14"
                    class="h100 base_cont_title_c"
                  >
                    <div class="qidai1">官方视频</div>
                  </el-col>
                  <el-col
                    :lg="7"
                    :md="8"
                    :sm="8"
                    :xs="5"
                    class="base_cont_title_r"
                  >
                    <div class="title_link"></div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div class="qidai2">敬请期待</div>
          </div>
        </div> -->
      </div>

      <div
        id="tag7"
        class="card cont7"
        :style="{ 'background-image': `url(${setHttps(config.image)})` }"
      >
        <div
          class="cont7_box animate__animated"
          :class="{ animate__fadeInUp: activeSection === 6 }"
        >
          <el-row type="flex" justify="center">
            <el-col :span="22">
              <el-row>
                <el-col :span="24" style="margin-bottom: 3.6458vw">
                  <div class="cont7_img_box">
                    <el-image
                      class="cont7_item_img bounce"
                      :src="setHttps(config.logo)"
                    ></el-image>
                  </div>
                </el-col>
                <el-col
                  class="text2"
                  :span="24"
                  style="margin-bottom: 1.8229vw"
                  >{{ config.title }}</el-col
                >
                <el-col
                  class="text3"
                  :span="24"
                  style="margin-bottom: 5.7291vw"
                  >{{ config.description }}</el-col
                >
                <el-col
                  :lg="24"
                  :md="24"
                  :sm="24"
                  :xs="24"
                  class="text4"
                  style="margin-bottom: 1.8229vw"
                >
                  <el-row class="text4_cont" type="flex" justify="center">
                    <el-col :lg="12" :md="14" :sm="16" :xs="18"
                      >Copyright ©
                      {{
                        config.url_year === my_time('y')
                          ? my_time('y')
                          : `${config.url_year}-${my_time('y')}`
                      }}
                      {{ config.company }}. 版权所有
                      <span
                        class="sethand"
                        @click.stop="jumpLink(config.icp_url)"
                        >{{ config.icp }}</span
                      ></el-col
                    >
                  </el-row>
                </el-col>
                <el-col v-if="config.security" class="text4" :span="24">
                  <div
                    class="gicon sethand"
                    @click.stop="jumpLink(config.security_url)"
                  >
                    <el-image
                      class="gicon_img"
                      :src="require('@/assets/img/g1.png')"
                    ></el-image>
                    <div class="gicon_text">{{ config.security }}</div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="showbarhei">
      <el-dialog
        custom-class="show_video_bar"
        :visible.sync="showVideo"
        v-if="showVideo"
        width="100%"
      >
        <div class="video_box">
          <div class="video_room">
            <video controls autoplay style="width: 100%; height: 100%">
              <source :src="setHttps(video_url)" type="video/mp4" />
            </video>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
module.exports = {
  directives: {
    'mouse-drag': {
      bind(el, binding, vnode) {
        let isMouseDown = false
        let startX
        let startX2
        let scrollLeft
        let scrollLeft2

        el.addEventListener('mousedown', (e) => {
          isMouseDown = true
          startX = e.pageX - el.offsetLeft
          startX2 = e.pageX - el.offsetLeft
          scrollLeft = el.scrollLeft
          scrollLeft2 = el.scrollLeft
        })

        el.addEventListener('mouseleave', () => {
          isMouseDown = false
        })

        el.addEventListener('mouseup', (e) => {
          isMouseDown = false
          let walk = e.pageX - startX2
          el.scrollLeft = scrollLeft2
          // 判断滑动方向
          if (walk < 0) {
            console.log('向左滑动')
            let scrollContainer = vnode.context.$refs.scrollContainer
            let childElement = scrollContainer.querySelector('.cont3_row_col')
            let scrollAmount = childElement.offsetWidth // 子元素的宽度
            let targetScrollLeft = scrollLeft2 + scrollAmount // 目标滚动位置
            vnode.context.animateScroll(
              scrollContainer,
              scrollLeft2,
              targetScrollLeft,
              300
            ) // 动画滚动
          } else {
            console.log('向右滑动')
            let scrollContainer = vnode.context.$refs.scrollContainer
            let childElement = scrollContainer.querySelector('.cont3_row_col')
            let scrollAmount = childElement.offsetWidth // 子元素的宽度
            let targetScrollLeft = scrollLeft2 - scrollAmount // 目标滚动位置
            vnode.context.animateScroll(
              scrollContainer,
              scrollLeft2,
              targetScrollLeft,
              300
            ) // 动画滚动
          }
        })

        el.addEventListener('mousemove', (e) => {
          if (!isMouseDown) return
          e.preventDefault()
          let x = e.pageX - el.offsetLeft
          const walk = (x - startX) * 1 // 控制滑动速度
          el.scrollLeft = scrollLeft - walk
        })
      },
    },
  },
  data: function () {
    return {
      // loading: false,
      config: '',

      menuState: false, //抽屉状态

      resize_state: false, //是否正在重绘

      page_info: '', //页面数据
      video_url: '', //视频路径
      showVideo: false, //视频播放状态

      activeSection: 0, // 设置默认选中第一个锚点
      tag_list: ['#tag1', '#tag2', '#tag3', '#tag4', '#tag5', '#tag6', '#tag7'],
      // tag_tit_list: ['HOME', 'OUR FEATURE', 'OUR PRODUCT', 'OUR SOLUTIONS', 'WHERE TO BUY', 'CONTACT US', 'HONCHITEC'],
      tag_tit_list: [
        'HOME',
        'OUR FEATURE',
        'OUR PRODUCT',
        'PRODUCT VIDEO',
        'CONTACT US',
        'NEWS',
        'HONCHITEC',
      ],
      param: {
        name: '',
        email: '',
        title: '',
        content: '',
      },

      swiperOptions: {
        pagination: '.swiper-pagination',
        // slidesPerView: 'auto',
        slidesPerView: '4',
        paginationClickable: true,
      },
      productSwiperOptions: {
        // slidesPerView: 'auto',
        slidesPerView: '3',
        spaceBetween: 30,
        paginationClickable: true,
        //loop: true
      },
      resize_state1: false,
      resize_state2: false,
      resize_state3: false,
      resize_state4: false,
      resize_state5: false,
      productVideoList: [],
      videoShow: false,
      videoUrl: '',
      newsSwiperOptions: {
        slidesPerView: '4',
        spaceBetween: 56,
        paginationClickable: true,
        //loop:true
      },
      newsList: [],
    }
  },
  created() {
    this.getConfig()
  },
  mounted() {
    this.getInfo()
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    // 播放视频
    playVideo(item) {
      // this.videoShow = true
      // this.videoUrl = item.video_url
      this.video_url = item.video_url
      this.showVideo = !this.showVideo
    },
    closeVideo() {
      this.videoShow = false
      this.videoUrl = ''
    },
    jumpProduct() {
      this.$router.push({ path: '/productVideo' })
    },
    jumpNews() {
      this.$router.push({ path: '/news' })
    },
    toNewsDetail(id) {
      this.$router.push({ path: '/newsDetail', query: { id } })
    },
    swiper() {
      let viewportWidth = window.innerWidth
      if (viewportWidth < 768) {
        this.swiperOptions.slidesPerView = '1' // 在视口宽度小于768px时，将slidesPerView修改为2
      }
      setTimeout(() => {
        console.log('===init-swiper', this.$refs.mySwiper)
        return this.$refs.mySwiper.$swiper
      }, 100)
    },
    productSwiper() {
      let viewportWidth = window.innerWidth
      if (viewportWidth < 768) {
        this.productSwiperOptions.slidesPerView = '1' // 在视口宽度小于768px时，将slidesPerView修改为2
        this.productSwiperOptions.spaceBetween = 0 // 在视口宽度小于768px时，将slidesPerView修改为2
      }
      setTimeout(() => {
        return this.$refs.myProductSwiper.$swiper
      }, 100)
    },
    newsSwiper() {
      let viewportWidth = window.innerWidth
      if (viewportWidth < 768) {
        this.newsSwiperOptions.slidesPerView = '1' // 在视口宽度小于768px时，将slidesPerView修改为2
        this.newsSwiperOptions.spaceBetween = 0 // 在视口宽度小于768px时，将slidesPerView修改为2
      }
      setTimeout(() => {
        return this.$refs.newsSwiper.$swiper
      }, 100)
    },
    /* 前一步 */
    clickPrev() {
      const that = this
      const length = that.deviceList.length
      if (that.activeBtn <= 0 || length == 0) {
        return false
      }
    },
    /* 后一步 */
    clickNext() {
      const that = this
      const length = that.deviceList.length
      if (that.activeBtn >= length - 1 || length == 0) {
        return false
      }
    },
    leftClick() {
      this.$refs.mySwiper.$swiper.slidePrev()
    },
    rightClick() {
      this.$refs.mySwiper.$swiper.slideNext()
    },

    clickVideoPrev() {
      this.$refs.myProductSwiper.$swiper.slidePrev()
    },
    clickVideoNext() {
      this.$refs.myProductSwiper.$swiper.slideNext()
    },

    // 预览图片
    previewClick(pathArr) {
      let imgArr = pathArr.map((i) => this.setHttps(i.image))
      this.$viewerApi({ images: imgArr })
    },
    // 跳转详情页新页面打开携带id
    jumpDetail(id) {
      let href = this.$router.resolve({
        path: '/detail',
        query: { id: id },
      }).href
      window.open(href, '_blank')
    },
    // 跳转
    jump(path, id) {
      if (path === 'ourdo') {
        this.$router.push({
          name: path,
          query: { id: id, tab: this.activeSection },
        })
      } else {
        this.$router.push({ name: path, query: { tab: this.activeSection } })
      }
    },
    // 提交
    subFun() {
      console.log('111111========')
      if (!this.param.name) {
        this.$notify.error({
          title: '提示',
          message: '请填写名字',
          position: 'top-right',
        })
        return
      }
      if (!this.param.email) {
        this.$notify.error({
          title: '提示',
          message: '请填写邮箱',
          position: 'top-right',
        })
        return
      }
      if (!this.param.title) {
        this.$notify.error({
          title: '提示',
          message: '请填写标题',
          position: 'top-right',
        })
        return
      }
      if (!this.param.content) {
        this.$notify.error({
          title: '提示',
          message: '请填写内容',
          position: 'top-right',
        })
        return
      }
      this.$('/api/Message/submitMessage', this.param).then((res) => {
        console.log(res)
        this.$notify({
          title: '提示',
          message: `${res.msg}`,
          type: 'success',
          position: 'top-right',
        })
        this.param = {
          name: '',
          email: '',
          title: '',
          content: '',
        }
      })
    },
    // 重绘
    resizeHandler() {
      if (!this.resize_state) {
        this.resize_state = true
        setTimeout(() => {
          this.$refs.mySwiper.$swiper.update() // 更新Swiper实例
          this.$refs.myProductSwiper.$swiper.update() // 更新Swiper实例
          this.$refs.myNewsSwiper.$swiper.update()
          if (window.innerWidth >= 1921) {
            if (!this.resize_state1) {
              this.resize_state1 = true
              setTimeout(() => {
                this.$refs.mySwiper.$swiper.params.slidesPerView = '4' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.$refs.myProductSwiper.$swiper.params.slidesPerView = '3' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.productSwiperOptions.spaceBetween = 30
                this.$refs.myNewsSwiper.$swiper.params.slidesPerView = '4' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.newsSwiperOptions.spaceBetween = 56
              }, 100)
            }
          } else {
            this.resize_state1 = false
          }
          if (window.innerWidth >= 1200 && window.innerWidth < 1921) {
            if (!this.resize_state2) {
              this.resize_state2 = true
              setTimeout(() => {
                this.$refs.mySwiper.$swiper.params.slidesPerView = '4' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.$refs.myProductSwiper.$swiper.params.slidesPerView = '3'
                this.productSwiperOptions.spaceBetween = 30
                this.$refs.myNewsSwiper.$swiper.params.slidesPerView = '4' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.newsSwiperOptions.spaceBetween = 56
              }, 100)
            }
          } else {
            this.resize_state2 = false
          }
          if (window.innerWidth >= 960 && window.innerWidth < 1199) {
            if (!this.resize_state3) {
              this.resize_state3 = true
              setTimeout(() => {
                this.$refs.mySwiper.$swiper.params.slidesPerView = '3' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.$refs.myProductSwiper.$swiper.params.slidesPerView = '2'
                this.productSwiperOptions.spaceBetween = 30
                this.$refs.myNewsSwiper.$swiper.params.slidesPerView = '3' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.newsSwiperOptions.spaceBetween = 56
              }, 100)
            }
          } else {
            this.resize_state3 = false
          }
          if (window.innerWidth >= 768 && window.innerWidth < 959) {
            if (!this.resize_state4) {
              this.resize_state4 = true
              setTimeout(() => {
                this.$refs.mySwiper.$swiper.params.slidesPerView = '2' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.$refs.myProductSwiper.$swiper.params.slidesPerView = '2'
                this.productSwiperOptions.spaceBetween = 30
                this.$refs.myNewsSwiper.$swiper.params.slidesPerView = '2' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.newsSwiperOptions.spaceBetween = 56
              }, 100)
            }
          } else {
            this.resize_state4 = false
          }
          if (window.innerWidth < 768) {
            if (!this.resize_state5) {
              this.resize_state5 = true
              setTimeout(() => {
                this.$refs.mySwiper.$swiper.params.slidesPerView = '1' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.$refs.myProductSwiper.$swiper.params.slidesPerView = '1'
                this.productSwiperOptions.spaceBetween = 0
                this.$refs.myNewsSwiper.$swiper.params.slidesPerView = '1' // 在视口宽度小于768px时，将slidesPerView修改为2
                this.newsSwiperOptions.spaceBetween = 0
              }, 100)
            }
          } else {
            this.resize_state5 = false
          }

          this.$refs.mySwiper.$swiper.update() // 更新Swiper实例
          this.$refs.myProductSwiper.$swiper.update() // 更新Swiper实例
          this.$refs.myNewsSwiper.$swiper.update() // 更新Swiper实例

          this.scrollTo(this.activeSection) //重绘锚点
          this.resize_state = false
        }, 1000)
      }
    },
    // 门把手
    doorknob() {
      this.menuState = !this.menuState
    },
    // 跳转外链
    jumpLink(url) {
      window.open(url, '_blank')
    },
    // 获取配置信息
    getConfig() {
      this.$('/api/index/getConfig').then((res) => {
        this.config = res.data
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll('link').forEach((link) => {
          if (link.rel === 'icon') {
            link.parentNode?.removeChild(link)
          }
        })
        let link = document.createElement('link')
        link.rel = 'icon'
        link.href = this.setHttps(res.data.icon) // 将网络图片的URL赋给href属性
        document.head.appendChild(link)

        // 设置页面的元信息
        document.title = this.config.seo_title
        let metaDescription = document.querySelector('meta[name="description"]')
        metaDescription.setAttribute('content', this.config.seo_description)
        let metaKeywords = document.querySelector('meta[name="keywords"]')
        metaKeywords.setAttribute('content', this.config.seo_keywords)
      })
    },
    // 获取数据
    getInfo() {
      this.$loading()
      this.$('/api/index/home')
        .then((res) => {
          console.log('页面数据===', res.data)
          this.page_info = res.data
          this.swiper()
          this.productSwiper()
          this.newsSwiper()
          window.addEventListener('scroll', this.handleScroll)
          console.log('携带值', this.$route.query.anchor)
          if (this.$route.query.anchor) {
            this.activeSection = this.$route.query.anchor
            setTimeout(() => {
              this.scrollTo(this.activeSection) // 设置默认选中第一个锚点,如果传进来的有值，就滚动到传进来的锚点
            }, 100)
          } else {
            // this.activeSection = 0
            setTimeout(() => {
              this.scrollTo(this.activeSection) // 设置默认选中第一个锚点,如果传进来的有值，就滚动到传进来的锚点
            }, 100)
          }
          this.productVideoList = res.data.video_list
          this.newsList = res.data.news_list
        })
        .finally(() => {
          // this.loading = false
          this.$loading().close()
        })
    },
    // 跳转富文本详情页
    jumpRich(info, type) {
      // console.log('=============', info, type)
      if (type === 'banner') {
        // 1图片
        // if ([1].includes(info.banner_type)) {
        //     this.$router.push({ name: 'richDetail', query: { id: info.id, table: type, titname: info.name } });
        // }
        // 2视频打开视频弹窗播放
        if ([2].includes(info.banner_type)) {
          this.video_url = info.video_url
          this.showVideo = !this.showVideo
        }
      }
    },
    // 滚动到哪个
    scrollTo(section) {
      this.menuState = false
      let targetElement = document.querySelector(this.tag_list[section])
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          duration: 600,
        })
      }
    },
    handleScroll() {
      for (let i = this.tag_list.length - 1; i >= 0; i--) {
        let currentSection = document.querySelector(this.tag_list[i])
        let rect = currentSection.getBoundingClientRect()
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          this.activeSection = i
          break
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
body {
  min-height: 148px;
  background-color: #666;
}

.index_page {
  overflow: hidden;

  .logoimg {
    width: 200px;
    height: 66.5px;
    position: fixed;
    top: 5px;
    left: 90px;
    z-index: 99000;
    // opacity: 1;
    // transform: rotate(0deg);
    // transition: transform 0.3s;
    ::v-deep {
      .el-image__inner {
        object-fit: contain !important;
      }
    }
  }

  // 菜单
  .menu {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 99000;
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  .menu:hover {
    opacity: 0;
    transform: rotate(180deg);
    transition: transform 0.2s;

    & + .arrows {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  .arrows {
    width: 48px;
    height: 48px;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 90000;
    opacity: 0;
    transform: rotate(-180deg);
    transition: transform 0.2s;
  }

  .menu_box {
    width: 320px;
    height: 100vh;
    background-color: #212121;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.64);
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 99900;
    transition: left 0.3s ease-in-out;
    overflow: auto;

    /* 或者使用 overflow: scroll; */
    /* 添加过渡效果 */
    &.menuShow {
      left: 0;
    }

    .menu_close {
      margin-left: 30px;
      color: #878787;
      font-size: 48px;

      &:hover {
        color: #fff;
      }
    }

    .menu_logo_box {
      width: 100%;
      padding: 15px 30px 80px;

      .menu_logo {
        width: 260px;
        height: 66px;
      }
    }

    .menu_tab_item {
      width: 100%;
      height: 46px;
      position: relative;

      .menu_tab_item_text {
        width: 100%;
        height: 46px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        padding: 0 30px;
        line-height: 46px;
        border-bottom: 1px solid #303030;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #878787;

        &.active {
          background-color: #404040;
          color: #ffffff;
        }

        &:hover {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .menu_tab_item_cover {
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background-color: #404040;
      }

      .menu_tab_item_text:hover + .menu_tab_item_cover {
        width: 100%;
        height: 100%;
        transition: width 0.25s;
      }

      .menu_tab_item_text:not(:hover) + .menu_tab_item_cover {
        width: 0;
        transition: width 1s;
      }
    }
  }

  .nav_box {
    width: 28px;
    height: 147px;
    position: fixed;
    z-index: 90000;
    margin-top: -73.5px;
    top: 50%;
    right: 17px;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);

    & > div {
      display: block;
      width: 20px;
      height: 20px;
      padding: 7px;
      cursor: pointer;
      position: relative;

      .dot {
        display: block;
        width: 10px;
        height: 10px;
        cursor: pointer;
        text-decoration: none;
        border: 2px solid rgba(255, 255, 255, 0.8);
        background-color: rgba(0, 0, 0, 0);
        border-radius: 50%;

        &.active {
          display: block;
          cursor: pointer;
          text-decoration: none;
          border: 2px solid rgba(255, 255, 255, 0.8);
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }

  .card {
    height: 100vh;

    &.cont1 {
      background-color: #fff;
      position: relative;

      .is-active {
        transform: none !important;
        opacity: 0;
        animation: opacity111 1s ease forwards;
      }

      .el-carousel__item {
        transition: transform 0.01s ease-in-out !important;
      }

      @keyframes opacity111 {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      .bot_btn {
        width: 30px;
        height: 62px;
        position: absolute;
        bottom: 30px;
        left: 50%;
        z-index: 100;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }

      &::v-deep .el-carousel__container {
        width: 100%;
        height: 100%;
      }

      &::v-deep .el-carousel__arrow {
        width: 60px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0.7);
        font-size: 30px;
      }

      &::v-deep .el-carousel__arrow--left {
        left: 60px;
      }

      &::v-deep .el-carousel__arrow--right {
        right: 60px;
      }

      &::v-deep .el-carousel__indicators--horizontal {
        display: none;
      }

      @media (max-width: 1200px) {
        &::v-deep .el-carousel__arrow--left {
          left: 30px;
        }

        &::v-deep .el-carousel__arrow--right {
          right: 30px;
        }
      }

      .banner_item {
        width: 100%;
        height: 100%;
      }
    }

    &.cont2 {
      height: auto;
      min-height: 100vh;
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;

      .cont2_box {
        width: 100%;
        max-width: 1200px;
        min-height: 100vh;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 50px;
        justify-content: center;
        align-content: center;
        text-align: center;
        opacity: 0;
        animation-duration: 1s;

        .cont2_box_r1 {
          width: 200px;
          height: 200px;
          margin: 0 auto;
        }

        .cont2_box_r2 {
          color: #fff;
          text-align: center;

          .cont2_box_r2_l {
            font-size: 48px;
            font-family: Arial Black-Regular, Arial Black;
            font-weight: 400;
            color: #a7a7a7;
            padding-right: 20px;
          }

          .cont2_box_r2_r {
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .cont2_box_r3 {
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 1.4;
          color: #a7a7a7;
          text-align: center;
        }

        .cont2_box_r4 {
          .cont2_box_r4_btn {
            width: 150px;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
            border-radius: 5px;
            background-color: #e5002f;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }

    &.cont3 {
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      .turning_btn_box {
        width: 100px;
        height: 50px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 999000;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        font-size: 24px;

        .turning_l {
          width: 100%;
          height: 100%;
          background-color: #212121;
          color: #a7a7a7;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          justify-items: center;
          align-items: center;

          &:hover {
            background: #474747;
            color: #fff;
          }
        }

        .turning_r {
          width: 100%;
          height: 100%;
          background-color: #212121;
          color: #a7a7a7;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          align-items: center;
          justify-items: center;

          &:hover {
            background: #474747;
            color: #fff;
          }
        }
      }

      .swiper_box {
        > div {
          height: 100%;

          display: inline-block;
          vertical-align: middle;
        }

        .swiper-button {
          width: 20px;
          height: 20px;
          cursor: pointer;
          text-align: center;
          padding: 0;
          font-weight: 500;
        }

        .swiper_ {
          width: 100%;
          background-color: #006064;

          .swiper-slide {
            // margin: 0 !important;
            height: 100%;
            height: 100%;
            min-height: 100vh;
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .item-device {
            height: 100%;
            width: 25vw;
            height: 100%;
            color: #fff;
            font-size: 48px;
            text-align: center;

            .device {
              height: 100%;
              min-height: 100vh;
              position: relative;
              background-size: cover !important;
              background-position: center;
              background-repeat: no-repeat;

              .col_box1 {
                font-size: 50px;
                font-family: Raleway-Bold, Raleway;
                font-weight: bold;
                color: #ffffff;
                line-height: 60px;
              }

              .col_box2 {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .col_box2_top {
                  width: 100%;
                  height: 50vh;
                  background-size: cover !important;
                  background-position: center;
                  background-repeat: no-repeat;
                }

                .col_box2_bot {
                  width: 100%;
                  height: 50vh;
                  padding: 45px 30px;
                  position: relative;

                  &.bgc_49 {
                    background-color: rgba(49, 49, 49, 1);

                    .col_title {
                      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                      font-size: 18px;
                      font-family: PingFang SC-Bold, PingFang SC;
                      font-weight: bold;
                      color: #ffffff;
                      display: grid;
                      grid-template-columns: repeat(1, 1fr);
                      justify-items: flex-start;
                      align-content: flex-end;

                      .col_title_text {
                        padding-bottom: 19px;
                      }

                      .col_title_link {
                        width: 40px;
                        height: 1px;
                        background-color: #fff;
                      }
                    }

                    .col_cont {
                      padding: 30px 0 56px;
                      font-size: 14px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #ffffff;
                      word-wrap: break-word;
                      white-space: normal;
                      text-align: left;
                    }

                    .col_btn_box {
                      font-size: 14px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #ffffff;
                      transition: color 0.5s;

                      /* 设置颜色过渡效果的持续时间为1秒 */
                      &:hover {
                        color: #fcb900;
                      }
                    }
                  }

                  &.bgc_255 {
                    background-color: #fff;

                    .col_title {
                      border-bottom: 1px solid #e0e0e0;
                      font-size: 18px;
                      font-family: PingFang SC-Bold, PingFang SC;
                      font-weight: bold;
                      color: #474747;
                      display: grid;
                      grid-template-columns: repeat(1, 1fr);
                      justify-items: flex-start;
                      align-content: flex-end;

                      .col_title_text {
                        padding-bottom: 19px;
                      }

                      .col_title_link {
                        width: 40px;
                        height: 1px;
                        background-color: #474747;
                      }
                    }

                    .col_cont {
                      padding: 30px 0 56px;
                      font-size: 14px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #474747;
                      word-wrap: break-word;
                      white-space: normal;
                      text-align: left;
                    }

                    .col_btn_box {
                      font-size: 14px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #474747;
                      transition: color 0.5s;

                      /* 设置颜色过渡效果的持续时间为1秒 */
                      &:hover {
                        color: #fcb900;
                      }
                    }
                  }

                  .top_triangle {
                    position: absolute;
                    top: 0;
                    margin-top: -39px;
                    left: 0;
                    z-index: 999000;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-top-width: 0;
                    border-right-width: 0;
                    border-bottom-width: 40px;
                    border-left-width: 100vw;
                    border-top-color: transparent;
                    border-right-color: transparent;
                    border-left-color: transparent;

                    &.borderc_49 {
                      border-bottom-color: rgba(49, 49, 49, 1);
                    }

                    &.borderc_255 {
                      border-bottom-color: #fff;
                    }
                  }

                  @media (min-width: 1200px) {
                    .item-device {
                      border-left-width: 25.5vw;
                    }
                  }

                  @media (min-width: 960px) {
                    .item-device {
                      border-left-width: 34vw;
                    }
                  }

                  @media (min-width: 720px) {
                    .item-device {
                      border-left-width: 50vw;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.cont4 {
      height: auto;
      min-height: 100vh;
      background-color: rgba(144, 144, 144, 1);

      .cont4_box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 100%;
        overflow: hidden;

        .cont4_item {
          width: 100%;
          height: 100%;
          background-size: cover !important;
          background-position: center;
          background-repeat: no-repeat;

          .cont4_item_product {
            width: 100%;
            padding-top: 73%;
            position: relative;

            .cont4_item_product_shade {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 100;
              width: 100%;
              height: 100%;
              background-color: rgba(255, 6, 6, 0.8);
              opacity: 0;
              transition: all 0.5s ease;

              .cont4_item_shade_box {
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                justify-content: center;
                align-content: center;
                row-gap: 30px;

                .cont4_item_shade_box_top {
                  text-align: center;
                  font-size: 18px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ffffff;
                }

                .cont4_item_shade_box_bot {
                  display: grid;
                  grid-template-columns: 33px 33px;
                  column-gap: 66px;
                  justify-content: center;
                  align-items: center;

                  .bot_icon {
                    width: 33px;
                    height: 33px;
                    border-radius: 50%;
                  }

                  .bot_icon:hover {
                    background-color: #212121;
                    transition: background-color 1s ease;
                  }
                }
              }
            }

            .cont4_item_product_shade:hover {
              opacity: 0.8;

              .cont4_item_shade_box {
                .cont4_item_shade_box_top {
                  animation: movey_t 0.5s ease-in-out forwards;
                }

                .cont4_item_shade_box_bot {
                  .move_l {
                    animation: movey_l 0.3s ease-in-out forwards;
                  }

                  .move_r {
                    animation: movey_r 0.3s ease-in-out forwards;
                  }
                }
              }

              /* 添加过渡效果 */
            }
          }
        }
      }

      @media (max-width: 767px) {
        .cont4_box {
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }

      .all_products {
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background-color: rgba(144, 144, 144, 1);
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }

    &.cont5 {
      height: auto;
      min-height: 100vh;
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      align-content: center;

      .base_cont_title_box {
        background-color: rgba(0, 0, 0, 0);
      }

      .cont5_box {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        .cont5_box_row {
          // height: 100%;

          .cont5_item {
            height: 100%;

            .cont5_item_img_box {
              display: flex;
              align-items: center;
              justify-content: center;

              .cont5_item_img {
                max-width: 195px;
                max-height: 195px;
              }
            }
          }
        }
      }

      .swiper-box {
        width: 100%;
        padding: 0 9%;
        position: relative;
        overflow: hidden;

        .item-product {
          width: calc((100% - 60px) / 3) !important;
          text-align: center;
          color: #fff;

          .image-box {
            height: 324px;
            width: 100%;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .play-btn {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                cursor: pointer;
              }
            }
          }

          .text {
            margin-top: 22px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .swiper-button-prev {
          background: #212121;
          width: 47px;
          height: 47px;
          color: transparent;
          left: 3%;
          cursor: pointer;

          .btn-icon {
            width: 25px;
            height: 25px;
            background: center no-repeat url('../../assets/img/arrow_left.png');
            background-size: 100% 100%;
            // border: 1px dashed #ccc;
          }
        }

        .swiper-button-next {
          background: #212121;
          width: 47px;
          height: 47px;
          color: transparent;
          right: 3%;
          cursor: pointer;

          .btn-icon {
            width: 25px;
            height: 25px;
            background: center no-repeat url('../../assets/img/arrow_right.png');
            background-size: 100% 100%;
            // border: 1px dashed #ccc;
          }
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          content: '';
        }

        @media (min-width: 1200px) {
          .item-product {
            width: calc((100% - 60px) / 3) !important;
          }
        }

        @media (min-width: 960px) and (max-width: 1199px) {
          .item-product {
            width: calc((100% - 30px) / 2) !important;
            .image-box {
              height: 300px !important;
            }
          }
        }
        @media (min-width: 768px) and (max-width: 959px) {
          & {
            padding: 0 11%;
          }
          .item-product {
            width: calc((100% - 30px) / 2) !important;
            .image-box {
              height: 280px !important;
            }
          }
        }
        @media (max-width: 767px) {
          & {
            padding: 0 0;
          }
          .item-product {
            width: 100% !important;
            .image-box {
              height: 240px !important;
            }
          }
        }
      }

      .more-btn {
        width: 116px;
        height: 40px;
        margin: 50px auto 0;
        border-radius: 5px;
        border: 1px solid #7d7d7c;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .news-list {
        margin-top: 22px;
        width: 100%;
        padding: 0 10%;
        overflow: hidden;
        .item-new {
          width: calc((100% - 168px) / 4) !important;
          // background: #fff;
          .img-box {
            width: 100%;
            height: 220px;
            cursor: pointer;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .img-title {
            margin-top: 15px;
            font-size: 18px;
            color: #fff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
          }
          .news-line {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            position: relative;
            &::after {
              content: '';
              width: 10%;
              height: 1px;
              background: #fff;
              position: absolute;
              left: 0;
            }
          }
          .news-content {
            color: #fff;
            font-size: 14px;
            line-height: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            margin-bottom: 20px;
          }
          .detail-btn {
            color: #fff;
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            cursor: pointer;
            img {
              width: 20px;
            }
          }
        }
        @media (min-width: 1200px) {
          .item-new {
            width: calc((100% - 168px) / 4) !important;
          }
        }

        @media (min-width: 960px) and (max-width: 1199px) {
          .item-new {
            width: calc((100% - 112px) / 3) !important;
          }
        }
        @media (min-width: 768px) and (max-width: 959px) {
          .item-new {
            width: calc((100% - 56px) / 2) !important;
            .image-box {
              height: 200px !important;
            }
          }
        }
        @media (max-width: 767px) {
          .item-new {
            width: 100% !important;
            .image-box {
              height: 180px !important;
            }
            .img-title {
              font-size: 16px;
            }
            .news-content {
              font-size: 13px;
            }
            .detail-btn {
              font-size: 13px;
            }
          }
        }
      }
      .more-btn {
        width: 116px;
        height: 40px;
        margin: 50px auto 0;
        border-radius: 5px;
        border: 1px solid #7d7d7c;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    &.cont6 {
      height: auto;
      min-height: 100vh;
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;
      padding: 30px 0 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      align-content: center;

      .base_cont_title_box {
        background-color: rgba(0, 0, 0, 0);
      }

      .cont6_box {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding-bottom: 50px;

        .cont6_box_row {
          padding: 30px 0 0;
          color: #fff;

          .cont6_box_row_col1 {
            padding-bottom: 30px;

            .sub_btn_box {
              padding-top: 30px;

              // width: 100%;
              // display: flex;
              // justify-content: flex-end;
              // align-items: center;
              .sub_btn {
                // width: ;
                height: 50px;
                line-height: 50px;
                border-radius: 5px;
                background-color: #474747;
                text-align: center;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              }
            }

            .base_ipt {
              margin-bottom: 30px;
              padding: 0 10px;
              height: 50px;
              border-bottom: 1px solid #ffffff;

              & > input {
                width: 100%;
                height: 100%;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }
            }

            .base_textarea {
              padding: 0 10px;
              height: 180px;
              border-bottom: 1px solid #ffffff;

              & > textarea {
                width: 100%;
                height: 100%;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }

          .cont6_box_row_col2 {
            .col_items {
              margin-bottom: 30px;
            }

            .tips_box {
              .tips_box_img {
                width: 50px;
                height: 50px;
              }

              .tips_box_cont {
                padding-left: 20px;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }
    }

    &.cont7 {
      height: auto;
      min-height: 100vh;
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;

      .cont7_box {
        width: 100%;
        max-width: 1200px;
        min-height: 100vh;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        align-items: center;
        text-align: center;
        opacity: 0;

        .text2 {
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }

        .text3 {
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #878787;
        }

        .text4 {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #878787;
          text-align: center;

          // .text4_cont {
          //     display: flex;
          //     justify-content: center;
          //     align-items: center;

          // }

          .gicon {
            display: flex;
            justify-content: center;
            align-items: center;

            .gicon_img {
              width: 16px;
              height: 16px;
            }

            .gicon_text {
              padding-left: 5px;
            }
          }
        }

        .cont7_img_box {
          display: flex;
          align-items: center;
          justify-content: center;

          .cont7_item_img {
            width: 520px;
            height: 95px;
          }
        }
      }
    }
  }

  // 公共title
  .base_cont_title_box {
    // background-color: #ccc;
    background-color: #474747;
    padding: 48px 0;
  }

  .base_cont_title_room {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
  }

  .base_cont_title {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    // opacity: 0;
    // transition: opacity 2s;

    .base_cont_title_l {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .base_cont_title_c {
      display: inline-block;
      vertical-align: bottom;
      font-size: 48px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }

    .base_cont_title_r {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .title_link {
      width: 40px;
      height: 1px;
      background-color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }
  .qidai-box {
    width: 100%;
    .img-box {
      width: 300px;
      margin: auto;
    }
    .qidai1 {
      color: #8c8c8c;
    }
    .qidai2 {
      font-size: 48px;
      color: #fff;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      text-align: center;
      margin-top: 40px;
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1180px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 42px;
      }
    }
    .qidai-box {
      .img-box {
        width: 250px;
      }
      .qidai2 {
        font-size: 42px;
      }
    }

    .cont7_item_img {
      width: 420px !important;
      height: 76.73px !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 36px;
      }

      .title_link {
        width: 30px;
      }
    }
    .qidai-box {
      .img-box {
        width: 220px;
      }
      .qidai2 {
        font-size: 36px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 33.333vw !important;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 28px;
      }

      .title_link {
        width: 30px;
      }
    }
    .qidai-box {
      .img-box {
        width: 180px;
      }
      .qidai2 {
        font-size: 28px;
      }
    }

    .cont7_item_img {
      width: 320px !important;
      height: 58.46px !important;
    }

    .cont3_row_col {
      width: 50vw !important;
    }
  }

  @media (max-width: 767px) {
    .base_cont_title {
      .base_cont_title_c {
        font-size: 24px;
      }

      .title_link {
        width: 20px;
      }
    }

    .qidai-box {
      .img-box {
        width: 150px;
      }
      .qidai2 {
        font-size: 24px;
      }
    }

    .cont7_item_img {
      width: 220px !important;
      height: 40.19px !important;
    }

    &::v-deep .el-carousel__arrow--left {
      display: block !important;
    }

    &::v-deep .el-carousel__arrow--right {
      display: block !important;
    }

    .cont3_row_col {
      width: 100vw !important;
    }
  }

  .showbarhei {
    &::v-deep .el-icon-close:before {
      color: #fff;
    }

    //
    &::v-deep .el-dialog {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
    }

    &::v-deep .el-dialog__header {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
    }
    // ::v-deep {
    //   .el-dialog__body {
    //     padding: 0;
    //   }
    // }
    @media(min-width: 768px) {
      .video_room {
        height: 60vh;
      }
    }
  }
}
.video-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .video-box {
    width: 80%;
    .video {
      width: 100%;
      // object-fit: contain;
    }
  }
}
</style>
