import Vue from 'vue'
import Viewer from 'v-viewer';//这行放在App.vue之前

import App from './App.vue'
import store from './store'
Vue.prototype.$store=store

//引入图片预览插件
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999000, // 设置图片预览组件的层级，确保能在其他组件之上
  },
});

// 引入axios
import axios from 'axios';
// 创建全局 Axios 实例
const axiosInstance = axios.create({
  // baseURL: 'http://qile.8.160.zlxtbj.com', // 替换为你的 API 地址
  baseURL: 'https://www.honchitec.com', // 替换为你的 API 地址
});
// 将 Axios 实例绑定到 Vue 原型中
Vue.prototype.$axios = axiosInstance;

//引入路由组件
import router from './router/index'
import VueRouter from 'vue-router'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

Vue.use(ElementUI);


import 'animate.css';//动效



/* 引入swiper */
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper /* { default options with global component } */)




import BaseHeader from '@/components/BaseHeader';
Vue.component('BaseHeader', BaseHeader);

import common from '@/utils/common.js';
for (let i in common) {
	Vue.prototype[i] = common[i]
}
//引入全局样式表
import './assets/css/common.css'

Vue.config.productionTip = false
Vue.use(VueRouter);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
