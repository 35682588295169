<template>
  <div></div>
</template>

<script>
export default {
  name: 'BaseHeader',
  data: function () {
    return {
     
    }
  },
  created() {
  
  },

  mounted() {

   


  },
  methods: {
    

  }
}
</script>

<style lang="scss" scoped>
.base_header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 90px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);



  .mov_btn {
    position: fixed;
    right: 0;
    top: calc(50vh - 115px);
    z-index: 9000;

    .mov_btn_box {
      .itm_box1 {
        position: relative;

        &:hover {
          .show_bars1 {
            display: block;
          }
        }
      }

      .itm_box2 {
        position: relative;

        &:hover {
          .show_bars2 {
            display: block;
          }
        }
      }

      .show_bars1 {
        display: none;
        position: absolute;
        right: 60px;
        min-width: 240px;
        top: 0;
        height: 85px;
        padding: 30px 25px;
        background-color: #fff;

        .show_bars1_room {
          display: grid;
          grid-template-columns: 24px auto;
          column-gap: 10px;
          justify-content: flex-end;
          align-items: center;

          .show_bars1_room_icon {
            width: 24px;
            height: 24px;
          }

          .show_bars1_room_text {
            font-size: 24px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .show_bars2 {
        display: none;
        position: absolute;
        top: -30px;
        right: 60px;
        width: 145px;
        height: 145px;
        background-color: #fff;
      }

      .mov_btn_box_item {
        width: 60px;
        height: 85px;
        border-bottom: 1px solid #f5f5f5;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-content: center;
        background-color: #fff;

        .mov_btn_box_item_icon {
          width: 24px;
          height: 24px;
        }


        .mov_btn_box_item_text {
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }

      }


      .topClick {
        width: 60px;
        height: 60px;
        background-color: #CEB05A;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-content: center;

        .topClick_icon {
          width: 28px;
          height: 28px;
        }


      }
    }



  }

  .base_header_logo_box {
    height: 100%;

    .base_header_logo {
      width: 70px;
      height: 59px;
    }
  }


  .base_header_cont {
    height: 100%;
    .base_header_tab {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      align-items: center;

      .base_header_tab_item {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 10px;
        justify-items: center;
        align-content: center;

        .base_header_tab_item_text {
          font-size: 15px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;

          &.act {
            color: #CEB05A;
          }
        }

        .base_header_tab_item_link {
          width: 39px;
          height: 2px;
          background-color: rgba(0, 0, 0, 0);

          &.act {
            background-color: #CEB05A;
          }
        }
      }
    }

    .ph_menu_box {

      background-color: #fff;

      .ph_menu_btn {
        font-size: 32px;
        color: #505050;
      }

      .ph_menu_list {
        width: 100%;
        position: absolute;
        top: 90px;
        right: 0;
        background-color: #fff;
        box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.15);

        .ph_menu_list_item {
          width: 100%;
          padding: 10px;

          &.act {
            color: #CEB05A;
          }

          &.out_login {
            text-align: center;
            color: #fff;
            background-color: #CEB05A;
          }
        }
      }
    }
    
  }

  .base_header_btn {
    width: 148px;
    height: 50px;
    border-radius: 5px;
    background-color: #ceb05a;
    line-height: 50px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 15px;
    justify-content: center;
    align-items: center;

    .base_header_btn_img {
      width: 12px;
      height: 10px;
    }
  }

  .base_header_btn2 {
    display: grid;
    grid-template-columns: 50px auto;
    column-gap: 10px;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .uname {
      white-space: nowrap;
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #482221;
    }

    .outlogin {
      position: absolute;
      right: -5.20833vw;
      width: 4.16666vw;
      border-radius: 5px;
      border: 1px solid #999;
      line-height: 34px;
      text-align: center;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #B9B9BD;
    }
  }


}
</style>
