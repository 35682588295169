/* eslint-disable no-mixed-spaces-and-tabs */
export default {
	// pathurl: 'http://qile.8.160.zlxtbj.com',
	pathurl: 'https://www.honchitec.com',
	$(url, data = {}) {
		let header = {};
		if (url.indexOf("http") < 0 || url.indexOf("https") < 0) {
			header = {
				"Content-Type": "application/json",
				"X-Requested-With": "XMLHttpRequest",
			}
		}
		return new Promise((resolve, reject) => {
			// console.log('请求参数---', data)
			this.$axios.post(
				url,
				data,
				{
					headers: header
				}
			).then(res => {
				if (res.data.code === 1) {
					this.$message.error(res.data.msg);
				}
				if (res.data.code === 1001) {
					// this.$router.push('/login');
					this.$message({
						showClose: true,
						message: '请先登录',
						type: 'warning'
					});
					return;
				} else {
					resolve(res.data);
				}


			}).catch(err => {
				console.log('request Err:' + JSON.stringify(err));
				reject(err);
			});
		});
	},
	my_time(type) {
		let date = new Date(),
			year = date.getFullYear(),
			month = date.getMonth() + 1,
			day = date.getDate(),
			hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
			minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
			second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
		month >= 1 && month <= 9 ? (month = "0" + month) : "";
		day >= 0 && day <= 9 ? (day = "0" + day) : "";
		let timer = '';
		if (type === 'ymd') {
			timer = `${year}-${month}-${day}`;
		} else if (type === 'ymdc') {
			timer = `${year}年${month}月${day}日`;
		} else if (type === 'y') {
			timer = `${year}`;
		} else if (type === 'ymdhds') {
			timer = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
		} else if (type === 'ym') {
			timer = `${year}-${month}`;
		} else if (type === 'g') {
			timer = `${year}/${month}/${day}`;
		} else {
			timer = `${year}-${month}-${day}`;
		}
		return timer;
	},
	// 设置富文本图片域名
	editContent(content) {
		let regex = /<img[^>]*src="([^"]+)"/g
		let processedContent = content.replace(regex, (match, capture) => {
			// capture 匹配到的图片路径
			let isAbsoluteURL = /^(https?:\/\/|http:\/\/)/i.test(capture); // 检查是否是绝对 URL
			if (!isAbsoluteURL) {
				// console.log('matchx---:',capture)
				capture = capture.replace(/\.\.\//g, '/');
				// 如果不是绝对 URL，则添加域名
				return `<img src="${this.pathurl}${capture}" style='max-width:100%;height:auto;'`;
			} else {
				console.log('match2---:', match)
				// 如果已经是绝对 URL，则不做修改
				match = match.replace(/\.\.\//g, '/');
				return match;
			}
		});
		return processedContent;
	},
	/***
	 * 时间戳格式化
	 * @param time 时间戳
	 * @param fmt
	 * @returns {string}
	 */
	formatTime(time = 0, fmt = 'yyyy-MM-dd hh:mm:ss') {
		if (time === 0 || !time) {
			return ''
		}
		if (time.toString().length < 13) time = time * 1000
		let date = new Date(time)
		if (/(y+)/.test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
		}
		let dayArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
		let o = {
			'M+': date.getMonth() + 1,
			'd+': date.getDate(),
			'h+': date.getHours(),
			'm+': date.getMinutes(),
			's+': date.getSeconds(),
			'week': dayArr[date.getDay()]
		};
		for (let k in o) {
			if (new RegExp(`(${k})`).test(fmt)) {
				let str = o[k] + '';
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
			}
		}
		return fmt;
	},
	// 校验手机号
	tel_reg(tel) {
		let reg = /^1[3-9]\d{9}$/
		if (reg.test(tel)) {
			return true
		} else {
			return false
		}
	},
	// 路径添加域名
	setHttps(url) {
		if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG|pdf|mp4)$/.test(url))
			return url
		if (url.substring(0, 4) != "http") {
			if (!/^\//.test(url)) {
				url = `/${url}`
			}
			url =`${this.pathurl}${url}`
		}
		return url
		// let randomNumber = Math.floor(Math.random() * 9) + 1;
		// if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG|pdf|mp4)$/.test(url))
		// 	return url
		// if (url.substring(0, 4) != "http") {
		// 	if (!/^\//.test(url)) {
		// 		url = `/${url}?key=${randomNumber}`
		// 	}
		// 	url =`${this.pathurl}${url}?key=${randomNumber}`
		// }
		// return url
	},


}